  .sidebar {
    display: block;
    width: calc(100% / 3.5);
    float: left;

    &--help {
        margin-top: 158px;
    }
  }

  @media (max-width: 800px) {
      .sidebar {
          width: 100%;
      }
  }