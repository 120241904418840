@charset "UTF-8";
/**
 * @author Tolja
 *
 * CONTENTS
 *
 * TOOLS
 * Mixins.................All mixins and functions on our website.
 *
 * SETTINGS
 * Colos..................Manage our color palette in isolation.
 * //Fonts..................All fonts and font-sizes.
 * //Images.................All images in our app.
 *
 * BASE
 * Body...................root styles.
 * Links..................Hyperlink styles.
 * Base helpers........... Simple helper classes.
 * //Animation..............All our animations.
 *
 * COMPONENTS (components are kinda like BASE just for every component, here should be classes that can be reused, and that won't be overridden)
 * Layout.................ui-layout, elements inside and their modifiers.
 * Inputfield.............ui-inputfield, elements inside and their modifiers.
 * Panelgrid..............ui-panelgrid, elements inside and their modifiers.
 * Button.................ui-button, elements inside and their modifiers.
 * Grid...................grid blueprints.
 * //Dialog.................ui-dialog and elements inside of it.
 * //Datatable..............ui-datatable and elements inside of it.
 *
 * PLACEHOLDERS
 * placeholders...........All code that is repeating a lot should go here.
 *
 * SPECIFIC
 * Accordion..............Specific accordionPanels.
 * Layout.................Specific layoutUnits.
 * //Datatable..............Specific datatables.
 * Dialog.................Specific dialogs.
 * InputField.............Specific inputfields.
 * Panelgrid..............Specific panelgrids.
 * Button.................Specific buttons.
 * //Radio..................Specific radio buttons.
 * Panel..................Specific panels.
 * Separator..............Specific separators.
 * //MenuButton.............Specific menu buttons.
 * //InputSwitch............Specific inputSwitchs.
 * //SelectOneMenu..........Specific selectOneMenus.
 * //Datagrid...............Specific datagrids.
 * CommandLink............Specific commandLinks.
 * Links..................Specific links.
 * Helpers................Specific, more complex, unique classes.
 *
 * ICONS
 * Icons..................All the icons in our app.
 *
 * MEDIA
 * Media..................All @media for responsiveness.
 *
 * SEMANTIC-UI (check https://semantic-ui.com for more details and documentation)
 * Drowdown...............Customize semantic dropdown.
 * Item...................Customize semantic items.
 * Search.................Customize semantic search.
 *
 * NOTE: Whoever continues editing this, NEVER OVERRIDE only if you must and only with specific styles and @media!! no style should override another style,
 * it is always better to create another class then overriding.
 *
 */
/**
 * #VENDORS
 */
/*
* FooTable v3 - FooTable is a jQuery plugin that aims to make HTML tables on smaller devices look awesome.
* @version 3.1.5
* @link http://fooplugins.com
* @copyright Steven Usher & Brad Vincent 2015
* @license Released under the GPLv3 license.
*/
/* globals - instead we house them under the .footable.table class as the root of FooTable is always the table itself. */
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
.footable.table, .footable.table *,
.footable-details.table, .footable-details.table * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.footable.table th,
.footable-details.table th {
  text-align: left; }

/* tables */
.footable.table,
.footable-details.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px; }

.footable.table > caption + thead > tr:first-child > th,
.footable.table > colgroup + thead > tr:first-child > th,
.footable.table > thead:first-child > tr:first-child > th,
.footable.table > caption + thead > tr:first-child > td,
.footable.table > colgroup + thead > tr:first-child > td,
.footable.table > thead:first-child > tr:first-child > td,
.footable-details.table > caption + thead > tr:first-child > th,
.footable-details.table > colgroup + thead > tr:first-child > th,
.footable-details.table > thead:first-child > tr:first-child > th,
.footable-details.table > caption + thead > tr:first-child > td,
.footable-details.table > colgroup + thead > tr:first-child > td,
.footable-details.table > thead:first-child > tr:first-child > td {
  border-top: 0; }

.footable.table > thead > tr > th,
.footable.table > tbody > tr > th,
.footable.table > tfoot > tr > th,
.footable.table > thead > tr > td,
.footable.table > tbody > tr > td,
.footable.table > tfoot > tr > td,
.footable-details.table > thead > tr > th,
.footable-details.table > tbody > tr > th,
.footable-details.table > tfoot > tr > th,
.footable-details.table > thead > tr > td,
.footable-details.table > tbody > tr > td,
.footable-details.table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd; }

.footable.table > thead > tr > th,
.footable.table > thead > tr > td,
.footable-details.table > thead > tr > th,
.footable-details.table > thead > tr > td {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd; }

.footable.table-condensed > thead > tr > th,
.footable.table-condensed > tbody > tr > th,
.footable.table-condensed > tfoot > tr > th,
.footable.table-condensed > thead > tr > td,
.footable.table-condensed > tbody > tr > td,
.footable.table-condensed > tfoot > tr > td,
.footable-details.table-condensed > thead > tr > th,
.footable-details.table-condensed > tbody > tr > th,
.footable-details.table-condensed > tfoot > tr > th,
.footable-details.table-condensed > thead > tr > td,
.footable-details.table-condensed > tbody > tr > td,
.footable-details.table-condensed > tfoot > tr > td {
  padding: 5px; }

.footable.table-bordered,
.footable-details.table-bordered {
  border: 1px solid #ddd; }

.footable.table-bordered > thead > tr > th,
.footable.table-bordered > tbody > tr > th,
.footable.table-bordered > tfoot > tr > th,
.footable.table-bordered > thead > tr > td,
.footable.table-bordered > tbody > tr > td,
.footable.table-bordered > tfoot > tr > td,
.footable-details.table-bordered > thead > tr > th,
.footable-details.table-bordered > tbody > tr > th,
.footable-details.table-bordered > tfoot > tr > th,
.footable-details.table-bordered > thead > tr > td,
.footable-details.table-bordered > tbody > tr > td,
.footable-details.table-bordered > tfoot > tr > td {
  border: 1px solid #ddd; }

.footable.table-bordered > thead > tr > th,
.footable.table-bordered > thead > tr > td,
.footable-details.table-bordered > thead > tr > th,
.footable-details.table-bordered > thead > tr > td {
  border-bottom-width: 2px; }

.footable.table-striped > tbody > tr:nth-child(odd),
.footable-details.table-striped > tbody > tr:nth-child(odd) {
  background-color: #f9f9f9; }

.footable.table-hover > tbody > tr:hover,
.footable-details.table-hover > tbody > tr:hover {
  background-color: #f5f5f5; }

/* buttons */
.footable .btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-appearance: button;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: visible;
  text-transform: none; }

.footable .btn:hover,
.footable .btn:focus,
.footable .btn.focus {
  color: #333;
  text-decoration: none; }

.footable .btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc; }

.footable .btn-default:hover,
.footable .btn-default:focus,
.footable .btn-default.focus,
.footable .btn-default:active,
.footable .btn-default.active,
.footable .open > .dropdown-toggle.btn-default {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad; }

.footable .btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4; }

.footable .btn-primary:hover,
.footable .btn-primary:focus,
.footable .btn-primary.focus,
.footable .btn-primary:active,
.footable .btn-primary.active,
.footable .open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #286090;
  border-color: #204d74; }

/* button groups */
.footable .btn-group, .footable .btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle; }

.footable .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.footable .btn-group > .btn:first-child {
  margin-left: 0; }

.footable .btn-group-vertical > .btn,
.footable .btn-group > .btn {
  position: relative;
  float: left; }

.footable .btn-group-xs > .btn,
.footable .btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

.footable .btn-group-sm > .btn,
.footable .btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

.footable .btn-group-lg > .btn,
.footable .btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px; }

/* caret */
.footable .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent; }

.footable .btn .caret {
  margin-left: 0; }

/* form-group */
.form-group {
  margin-bottom: 15px; }

/* form-control */
.footable .form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  margin: 0;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }

/* input-group */
.footable .input-group {
  position: relative;
  display: table;
  border-collapse: separate; }

.footable .input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0; }

.footable .input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap; }

.footable .input-group-addon,
.footable .input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle; }

.footable .input-group-addon,
.footable .input-group-btn,
.footable .input-group .form-control {
  display: table-cell; }

.footable .input-group-btn > .btn + .btn,
.footable .input-group-btn:last-child > .btn,
.footable .input-group-btn:last-child > .btn-group {
  margin-left: -1px; }

.footable .input-group-btn > .btn {
  position: relative; }

.footable .input-group-btn > .btn:hover,
.footable .input-group-btn > .btn:focus,
.footable .input-group-btn > .btn:active {
  z-index: 2; }

.footable .input-group .form-control:first-child,
.footable .input-group-addon:first-child,
.footable .input-group-btn:first-child > .btn,
.footable .input-group-btn:first-child > .btn-group > .btn,
.footable .input-group-btn:first-child > .dropdown-toggle,
.footable .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.footable .input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.footable .input-group .form-control:last-child,
.footable .input-group-addon:last-child,
.footable .input-group-btn:last-child > .btn,
.footable .input-group-btn:last-child > .btn-group > .btn,
.footable .input-group-btn:last-child > .dropdown-toggle,
.footable .input-group-btn:first-child > .btn:not(:first-child),
.footable .input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

/* checkboxes & radios */
.footable .radio,
.footable .checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px; }

.footable .radio label,
.footable .checkbox label {
  max-width: 100%;
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer; }

.footable .radio input[type=radio],
.footable .radio-inline input[type=radio],
.footable .checkbox input[type=checkbox],
.footable .checkbox-inline input[type=checkbox] {
  position: absolute;
  margin: 4px 0 0 -20px;
  line-height: normal; }

/* dropdown-menu */
.footable .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); }

.footable .open > .dropdown-menu {
  display: block; }

.footable .dropdown-menu-right {
  right: 0;
  left: auto; }

.footable .dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap; }

.footable .dropdown-menu > li > a:hover,
.footable .dropdown-menu > li > a:focus {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5; }

/* pagination */
.footable .pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px; }

.footable .pagination > li {
  display: inline; }

.footable .pagination > li:first-child > a,
.footable .pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }

.footable .pagination > li > a,
.footable .pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd; }

.footable .pagination > li > a:hover,
.footable .pagination > li > span:hover,
.footable .pagination > li > a:focus,
.footable .pagination > li > span:focus {
  color: #23527c;
  background-color: #eee;
  border-color: #ddd; }

.footable .pagination > .active > a,
.footable .pagination > .active > span,
.footable .pagination > .active > a:hover,
.footable .pagination > .active > span:hover,
.footable .pagination > .active > a:focus,
.footable .pagination > .active > span:focus {
  z-index: 2;
  color: #fff;
  cursor: default;
  background-color: #337ab7;
  border-color: #337ab7; }

.footable .pagination > .disabled > span,
.footable .pagination > .disabled > span:hover,
.footable .pagination > .disabled > span:focus,
.footable .pagination > .disabled > a,
.footable .pagination > .disabled > a:hover,
.footable .pagination > .disabled > a:focus {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd; }

/* labels */
.footable .label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em; }

.footable .label-default {
  background-color: #777; }

/* wells */
.footable-loader.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05); }

/* screen reader only */
.footable .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

/* form-inline */
@media (min-width: 768px) {
  .footable .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle; }
  .footable .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle; }
  .footable .form-inline .input-group {
    display: inline-table;
    vertical-align: middle; }
  .footable .form-inline .input-group .input-group-addon,
  .footable .form-inline .input-group .input-group-btn,
  .footable .form-inline .input-group .form-control {
    width: auto; }
  .footable .form-inline .input-group > .form-control {
    width: 100%; } }

table.footable,
table.footable-details {
  position: relative;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse; }

table.footable-details {
  margin-bottom: 0; }

table.footable-hide-fouc {
  display: none; }

table > tbody > tr > td > span.footable-toggle {
  margin-right: 8px;
  opacity: 0.3; }

table > tbody > tr > td > span.footable-toggle.last-column {
  margin-left: 8px;
  float: right; }

table.table-condensed > tbody > tr > td > span.footable-toggle {
  margin-right: 5px; }

table.footable-details > tbody > tr > th:nth-child(1) {
  min-width: 40px;
  width: 120px; }

table.footable-details > tbody > tr > td:nth-child(2) {
  word-break: break-all; }

table.footable-details > thead > tr:first-child > th,
table.footable-details > thead > tr:first-child > td,
table.footable-details > tbody > tr:first-child > th,
table.footable-details > tbody > tr:first-child > td,
table.footable-details > tfoot > tr:first-child > th,
table.footable-details > tfoot > tr:first-child > td {
  border-top-width: 0; }

table.footable-details.table-bordered > thead > tr:first-child > th,
table.footable-details.table-bordered > thead > tr:first-child > td,
table.footable-details.table-bordered > tbody > tr:first-child > th,
table.footable-details.table-bordered > tbody > tr:first-child > td,
table.footable-details.table-bordered > tfoot > tr:first-child > th,
table.footable-details.table-bordered > tfoot > tr:first-child > td {
  border-top-width: 1px; }

div.footable-loader {
  vertical-align: middle;
  text-align: center;
  height: 300px;
  position: relative; }

div.footable-loader > span.fooicon {
  display: inline-block;
  opacity: 0.3;
  font-size: 30px;
  line-height: 32px;
  width: 32px;
  height: 32px;
  margin-top: -16px;
  margin-left: -16px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-animation: fooicon-spin-r 2s infinite linear;
  animation: fooicon-spin-r 2s infinite linear; }

table.footable > tbody > tr.footable-empty > td {
  vertical-align: middle;
  text-align: center;
  font-size: 30px; }

table.footable > tbody > tr > td,
table.footable > tbody > tr > th {
  display: none; }

table.footable > tbody > tr.footable-empty > td,
table.footable > tbody > tr.footable-empty > th,
table.footable > tbody > tr.footable-detail-row > td,
table.footable > tbody > tr.footable-detail-row > th {
  display: table-cell; }

@-webkit-keyframes fooicon-spin-r {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes fooicon-spin-r {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

.fooicon {
  display: inline-block;
  font-size: inherit;
  font-family: FontAwesome !important;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0); }

.fooicon:before,
.fooicon:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.fooicon-loader:before {
  content: "\f01e"; }

.fooicon-plus:before {
  content: "\f067"; }

.fooicon-minus:before {
  content: "\f068"; }

.fooicon-search:before {
  content: "\f002"; }

.fooicon-remove:before {
  content: "\f00d"; }

.fooicon-sort:before {
  content: "\f0dc"; }

.fooicon-sort-asc:before {
  content: "\f160"; }

.fooicon-sort-desc:before {
  content: "\f161"; }

.fooicon-pencil:before {
  content: "\f040"; }

.fooicon-trash:before {
  content: "\f1f8"; }

.fooicon-eye-close:before {
  content: "\f070"; }

.fooicon-flash:before {
  content: "\f0e7"; }

.fooicon-cog:before {
  content: "\f013"; }

.fooicon-stats:before {
  content: "\f080"; }

table.footable > thead > tr.footable-filtering > th {
  border-bottom-width: 1px;
  font-weight: normal; }

.footable-filtering-external.footable-filtering-right,
table.footable > thead > tr.footable-filtering > th,
table.footable.footable-filtering-right > thead > tr.footable-filtering > th {
  text-align: right; }

.footable-filtering-external.footable-filtering-left,
table.footable.footable-filtering-left > thead > tr.footable-filtering > th {
  text-align: left; }

.footable-filtering-external.footable-filtering-center,
table.footable.footable-filtering-center > thead > tr.footable-filtering > th {
  text-align: center; }

table.footable > thead > tr.footable-filtering > th div.form-group {
  margin-bottom: 0; }

table.footable > thead > tr.footable-filtering > th div.form-group + div.form-group {
  margin-top: 5px; }

table.footable > thead > tr.footable-filtering > th div.input-group {
  width: 100%; }

.footable-filtering-external ul.dropdown-menu > li > a.checkbox,
table.footable > thead > tr.footable-filtering > th ul.dropdown-menu > li > a.checkbox {
  margin: 0;
  display: block;
  position: relative; }

.footable-filtering-external ul.dropdown-menu > li > a.checkbox > label,
table.footable > thead > tr.footable-filtering > th ul.dropdown-menu > li > a.checkbox > label {
  display: block;
  padding-left: 20px; }

.footable-filtering-external ul.dropdown-menu > li > a.checkbox input[type="checkbox"],
table.footable > thead > tr.footable-filtering > th ul.dropdown-menu > li > a.checkbox input[type="checkbox"] {
  position: absolute;
  margin-left: -20px; }

@media (min-width: 768px) {
  table.footable > thead > tr.footable-filtering > th div.input-group {
    width: auto; }
  table.footable > thead > tr.footable-filtering > th div.form-group {
    margin-left: 2px;
    margin-right: 2px; }
  table.footable > thead > tr.footable-filtering > th div.form-group + div.form-group {
    margin-top: 0; } }

table.footable > thead > tr > td.footable-sortable,
table.footable > thead > tr > th.footable-sortable,
table.footable > tbody > tr > td.footable-sortable,
table.footable > tbody > tr > th.footable-sortable,
table.footable > tfoot > tr > td.footable-sortable,
table.footable > tfoot > tr > th.footable-sortable {
  position: relative;
  padding-right: 30px;
  cursor: pointer; }

td.footable-sortable > span.fooicon,
th.footable-sortable > span.fooicon {
  position: absolute;
  right: 6px;
  top: 50%;
  margin-top: -7px;
  opacity: 0;
  transition: opacity 0.3s ease-in; }

td.footable-sortable:hover > span.fooicon,
th.footable-sortable:hover > span.fooicon {
  opacity: 1; }

td.footable-sortable.footable-asc > span.fooicon,
th.footable-sortable.footable-asc > span.fooicon,
td.footable-sortable.footable-desc > span.fooicon,
th.footable-sortable.footable-desc > span.fooicon {
  opacity: 1; }

/* hides the sort icons when sorting is not allowed */
table.footable-sorting-disabled td.footable-sortable.footable-asc > span.fooicon,
table.footable-sorting-disabled td.footable-sortable.footable-desc > span.fooicon,
table.footable-sorting-disabled td.footable-sortable:hover > span.fooicon,
table.footable-sorting-disabled th.footable-sortable.footable-asc > span.fooicon,
table.footable-sorting-disabled th.footable-sortable.footable-desc > span.fooicon,
table.footable-sorting-disabled th.footable-sortable:hover > span.fooicon {
  opacity: 0;
  visibility: hidden; }

.footable-paging-external ul.pagination,
table.footable > tfoot > tr.footable-paging > td > ul.pagination {
  margin: 10px 0 0 0; }

.footable-paging-external span.label,
table.footable > tfoot > tr.footable-paging > td > span.label {
  display: inline-block;
  margin: 0 0 10px 0;
  padding: 4px 10px; }

.footable-paging-external.footable-paging-center,
table.footable > tfoot > tr.footable-paging > td,
table.footable-paging-center > tfoot > tr.footable-paging > td {
  text-align: center; }

.footable-paging-external.footable-paging-left,
table.footable-paging-left > tfoot > tr.footable-paging > td {
  text-align: left; }

.footable-paging-external.footable-paging-right,
table.footable-paging-right > tfoot > tr.footable-paging > td {
  text-align: right; }

ul.pagination > li.footable-page {
  display: none; }

ul.pagination > li.footable-page.visible {
  display: inline; }

td.footable-editing {
  width: 90px;
  max-width: 90px; }

table.footable-editing-no-edit td.footable-editing,
table.footable-editing-no-delete td.footable-editing,
table.footable-editing-no-view td.footable-editing {
  width: 70px;
  max-width: 70px; }

table.footable-editing-no-edit.footable-editing-no-delete td.footable-editing,
table.footable-editing-no-edit.footable-editing-no-view td.footable-editing,
table.footable-editing-no-delete.footable-editing-no-view td.footable-editing {
  width: 50px;
  max-width: 50px; }

table.footable-editing-no-edit.footable-editing-no-delete.footable-editing-no-view td.footable-editing,
table.footable-editing-no-edit.footable-editing-no-delete.footable-editing-no-view th.footable-editing {
  width: 0;
  max-width: 0;
  display: none !important; }

table.footable-editing-right td.footable-editing,
table.footable-editing-right tr.footable-editing {
  text-align: right; }

table.footable-editing-left td.footable-editing,
table.footable-editing-left tr.footable-editing {
  text-align: left; }

table.footable-editing button.footable-add,
table.footable-editing button.footable-hide,
table.footable-editing-show button.footable-show,
table.footable-editing.footable-editing-always-show button.footable-show,
table.footable-editing.footable-editing-always-show button.footable-hide,
table.footable-editing.footable-editing-always-show.footable-editing-no-add tr.footable-editing {
  display: none; }

table.footable-editing.footable-editing-show button.footable-add,
table.footable-editing.footable-editing-show button.footable-hide,
table.footable-editing.footable-editing-always-show button.footable-add {
  display: inline-block; }

/**
 * #TOOLS
 */
/**
 * #SETTINGS
 */
/**
 * #BASE
 */
@font-face {
  font-family: "robotoregular";
  src: url("../fonts/roboto-regular-webfont.eot");
  src: url("../fonts/roboto-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-regular-webfont.woff2") format("woff2"), url("../fonts/roboto-regular-webfont.woff") format("woff"), url("../fonts/roboto-regular-webfont.ttf") format("truetype"), url("../fonts/roboto-regular-webfont.svg#robotoregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "robotolight";
  src: url("../fonts/roboto-light-webfont.eot");
  src: url("../fonts/roboto-light-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-light-webfont.woff2") format("woff2"), url("../fonts/roboto-light-webfont.woff") format("woff"), url("../fonts/roboto-light-webfont.ttf") format("truetype"), url("../fonts/roboto-light-webfont.svg#robotolight") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "robotomedium";
  src: url("../fonts/roboto-medium-webfont.eot");
  src: url("../fonts/roboto-medium-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-medium-webfont.woff2") format("woff2"), url("../fonts/roboto-medium-webfont.woff") format("woff"), url("../fonts/roboto-medium-webfont.ttf") format("truetype"), url("../fonts/roboto-medium-webfont.svg#robotomedium") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "robotoregular";
  src: url("../fonts/Roboto-Bold.eot");
  src: url("../fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Bold.woff2") format("woff2"), url("../fonts/Roboto-Bold.woff") format("woff"), url("../fonts/Roboto-Bold.ttf") format("truetype"), url("../fonts/Roboto-Bold.svg#Roboto") format("svg");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "robotolight";
  src: url("../fonts/Roboto-Bold.eot");
  src: url("../fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Bold.woff2") format("woff2"), url("../fonts/Roboto-Bold.woff") format("woff"), url("../fonts/Roboto-Bold.ttf") format("truetype"), url("../fonts/Roboto-Bold.svg#Roboto") format("svg");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "robotomedium";
  src: url("../fonts/Roboto-Bold.eot");
  src: url("../fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Bold.woff2") format("woff2"), url("../fonts/Roboto-Bold.woff") format("woff"), url("../fonts/Roboto-Bold.ttf") format("truetype"), url("../fonts/Roboto-Bold.svg#Roboto") format("svg");
  font-weight: bold;
  font-style: normal; }

body {
  margin: 0;
  font-family: "robotoregular", "robotolight", "robotomedium"; }

*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

img {
  border: none; }

h1 {
  font-weight: 400; }

h2 {
  font-weight: 400; }

h3 {
  font-size: 20px;
  font-weight: 400; }

h4 {
  font-size: 14px;
  font-weight: 400; }

a {
  text-decoration: none;
  color: black; }

.ui-width--25 {
  width: 25px; }

.ui-width--33 {
  width: 33px; }

.ui-width--100 {
  width: 100px; }

.ui-width--190 {
  width: 190px; }

.ui-width--200 {
  width: 200px; }

.ui-width--215 {
  width: 215px; }

.ui-width--230 {
  width: 230px; }

.ui-width--284 {
  width: 284px; }

.ui-width--300 {
  width: 300px; }

.ui-border-radius--4 {
  border-radius: 4px; }

.ui-border-radius--5 {
  border-radius: 5px; }

.ui-font-size--11 {
  font-size: 11px; }

.ui-font-size--12 {
  font-size: 12px; }

.ui-font-size--13 {
  font-size: 13px; }

.ui-font-size--14 {
  font-size: 14px; }

.ui-font-size--15 {
  font-size: 15px; }

.ui-font-size--16 {
  font-size: 16px; }

.ui-font-size--17 {
  font-size: 17px; }

.ui-font-size--18 {
  font-size: 18px; }

.ui-font-size--19 {
  font-size: 19px; }

.ui-font-size--20 {
  font-size: 20px; }

.ui-font-size--22 {
  font-size: 22px; }

.ui-font-size--24 {
  font-size: 24px; }

.ui-font-size--25 {
  font-size: 25px; }

.ui-font-size--27 {
  font-size: 27px; }

.ui-font-size--29 {
  font-size: 29px; }

.ui-font-size--30 {
  font-size: 30px; }

.ui-font-size--35 {
  font-size: 35px; }

.ui-font-size--36 {
  font-size: 36px; }

.ui-font-size--38 {
  font-size: 38px; }

.ui-font-size--39 {
  font-size: 39px; }

.ui-font-size--40 {
  font-size: 40px; }

.ui-font-size--85 {
  font-size: 85px; }

.ui-line-height--24 {
  line-height: 24px; }

.ui-line-height--27 {
  line-height: 27px; }

.ui-line-height--30 {
  line-height: 30px; }

.ui-padding-left--13 {
  padding-left: 13px; }

.ui-padding-left--23 {
  padding-left: 23px; }

.ui-padding-left--30 {
  padding-left: 30px; }

.ui-padding-left--60 {
  padding-left: 60px; }

.ui-padding-right--10 {
  padding-right: 10px; }

.ui-padding-right--20 {
  padding-right: 20px; }

.ui-padding-right--30 {
  padding-right: 30px; }

.ui-padding-top--8 {
  padding-top: 8px; }

.ui-padding-top--10 {
  padding-top: 10px; }

.ui-padding-top--15 {
  padding-top: 15px; }

.ui-padding-top--20 {
  padding-top: 20px; }

.ui-padding-bottom--10 {
  padding-bottom: 10px; }

.ui-padding-bottom--15 {
  padding-bottom: 15px; }

.ui-padding-bottom--25 {
  padding-bottom: 25px; }

.ui-padding--10 {
  padding: 10px; }

.ui-padding--15 {
  padding: 15px; }

.ui-padding--20 {
  padding: 20px; }

.ui-margin-top--5 {
  margin-top: 5px; }

.ui-margin-top--8 {
  margin-top: 8px; }

.ui-margin-top--10 {
  margin-top: 10px; }

.ui-margin-top--12 {
  margin-top: 12px; }

.ui-margin-top--15 {
  margin-top: 15px; }

.ui-margin-top--25 {
  margin-top: 25px; }

.ui-margin-right--5 {
  margin-right: 5px; }

.ui-margin-right--8 {
  margin-right: 8px; }

.ui-margin-right--10 {
  margin-right: 10px; }

.ui-margin-right--12 {
  margin-right: 12px; }

.ui-margin-right--25 {
  margin-right: 25px; }

.ui-margin-left--40 {
  margin-left: 40px; }

.ui-margin-left--10 {
  margin-left: 10px; }

.ui-margin-bottom--7 {
  margin-bottom: 7px; }

.ui-margin-bottom--10 {
  margin-bottom: 10px; }

.ui-margin-bottom--11 {
  margin-bottom: 11px; }

.ui-margin-bottom--15 {
  margin-bottom: 15px; }

.ui-margin-bottom--30 {
  margin-bottom: 30px; }

.ui-height--5 {
  height: 5px; }

.ui-height--8 {
  height: 8px; }

.ui-height--20 {
  height: 20px; }

.ui-height--25 {
  height: 25px; }

.ui-height--33 {
  height: 33px; }

.ui-height--45 {
  height: 45px; }

.ui-height--50 {
  height: 50px; }

.ui-height--85 {
  height: 85px; }

.ui-height--100 {
  height: 100px; }

.ui-height--150 {
  height: 150px; }

.ui-border-radius--full {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%; }

.ui-border-radius--none {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.ui-max-width--full {
  max-width: 100%; }

.ui-width--full {
  width: 100%; }

.ui-height--full {
  height: 100%; }

.ui-width--half {
  width: 50%; }

.ui-width--75 {
  width: 75%; }

.ui-width--quad {
  width: 25%; }

.ui-line-height--full {
  line-height: 100%; }

.clear {
  clear: both;
  margin: 0;
  padding: 0; }

.ui-position--relative {
  position: relative; }

.ui-float--right {
  float: right; }

.ui-float--left {
  float: left; }

.ui-text-align--center {
  text-align: center; }

.ui-text-align--right {
  text-align: right; }

.ui-text-align--left {
  text-align: left; }

.ui-font--light {
  font-family: "robotolight"; }

.ui-font--medium {
  font-family: "robotomedium"; }

.ui-font--bold {
  font-weight: bold; }

.ui-color--white {
  color: #fff; }

.ui-color--gray {
  color: #545454; }

.ui-color--light-grey {
  color: #888; }

.ui-color--green {
  color: #57c176; }

.ui-margin--center {
  margin: 0 auto; }

.ui-border {
  border: 1px solid #ccc; }

.ui-border-r {
  border-right: 1px solid #ccc; }

.ui-border-l {
  border-left: 1px solid #ccc; }

.ui-border-b {
  border-bottom: 1px solid #ccc; }

.ui-border--green {
  border-color: #25be4a; }

.ui-border-none {
  border: none; }

.ui-border-t-none {
  border-top: none; }

.ui-border-b-none {
  border-bottom: none; }

.ui-border-l-none {
  border-left: none; }

.ui-border-r-none {
  border-right: none; }

.ui-background--none {
  background: none; }

.ui-vertical-align--middle {
  vertical-align: middle; }

.ui-display--block {
  display: block; }

.ui-display--inblock {
  display: inline-block; }

.ui-display--none {
  display: none; }

.ui-display--never-none {
  display: none !important; }

.ui-color--dark-gray {
  color: #29333d; }

.ui-table-layout--fixed {
  table-layout: fixed; }

.ui-text--wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }

.ui-width--full-175 {
  width: -moz-calc(100% - 175px);
  width: -webkit-calc(100% - 175px);
  width: calc(100% - 175px); }

.ui-background--gray {
  background: #f7f7f7; }

.ui-background--white {
  background: #fff; }

.ui-color--red {
  color: #ff3300; }

.ui-separator--top-gray {
  border-top: 1px solid #e0e0e0; }

.ui-overflow--hidden {
  overflow: hidden; }

.ui-cursor--pointer {
  cursor: pointer; }

.ui-color--blue {
  color: #0394ea; }

.ui-color--orange {
  color: #f39335; }

.ui-color--greece {
  color: #0D5EAF; }

.ui-color--cyprus {
  color: #f39335; }

.ui-color--sm-grey {
  color: #888; }

.ui-text--uppercase {
  text-transform: uppercase; }

.ui-padding-15-0 {
  padding: 15px 0; }

.ui-padding-10-0 {
  padding: 10px 0; }

.ui-padding-10-20 {
  padding: 10px 20px; }

.ui-padding-0-10 {
  padding: 0 10px; }

.ui-padding-25-0 {
  padding: 25px 0; }

.ui-padding-15-20 {
  padding: 15px 20px; }

.ui-margin--n {
  margin: 0; }

.ui-margin-5-0 {
  margin: 5px 0; }

.ui-margin-30-0 {
  margin: 30px 0; }

.u-word-wrap--brw {
  word-wrap: break-word; }

.u-box-sizing--cb {
  box-sizing: content-box; }

.ui-margin-m1em-b {
  margin-bottom: -1em; }

.ui-padding-left--1em {
  padding-left: 1em; }

.sticky {
  position: fixed;
  z-index: -1;
  margin-top: 110px; }

.stop {
  position: relative;
  z-index: 0; }

.sticky-header {
  position: sticky;
  top: 0;
  background: white;
  z-index: 998; }

.sticky-header-shadow {
  box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.1); }

ul {
  list-style: none;
  padding: 0;
  margin: 0; }

/**
 * #COMPONENTS
 */
/**
 * West layout styles (used as a web-page main header). ******************************************************************************************************************
 */
.ui-layout-west__content {
  max-width: 1170px;
  padding: 10px 15px;
  margin: 0 auto; }

.header__login {
  display: none; }

/**
 * Center layout styles (used as a web-page main content). ******************************************************************************************************************
 */
/**
 * webpage footer ******************************************************************************************************************
 */
.ui-footer {
  margin-top: 50px;
  background: #3F3F3F; }

.ui-footer__content {
  padding-top: 30px;
  max-width: 1170px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px; }

@media (max-width: 1000px) {
  .header__login {
    display: inline; }
  .header__log-reg {
    display: none; }
  #loginBlock {
    display: none; } }

.ui-inputfield input {
  border: 0;
  width: 100%;
  padding: 0;
  font-size: 15px;
  background: none;
  color: #8c8c8c; }

.ui-inputfield input:focus {
  outline: 0; }

.ui-panelgrid {
  border-spacing: 0; }

.ui-panelgrid__subheader {
  font-family: "robotomedium";
  color: #1e1e1e;
  padding: 15px 13px 8px 13px;
  font-weight: bold; }

.ui-panelgrid__output {
  color: #545454; }

.ui-panelgrid__image {
  height: 100px;
  background: center center / auto no-repeat; }

.ui-panelgrid__footer {
  padding: 23px 0 13px 13px; }

.ui-button {
  -webkit-transition: background-color 0.4s ease, box-shadow 0.4s ease;
  -moz-transition: background-color 0.4s ease, box-shadow 0.4s ease;
  -o-transition: background-color 0.4s ease, box-shadow 0.4s ease;
  transition: background-color 0.4s ease, box-shadow 0.4s ease;
  position: relative;
  padding: 0 18px;
  border: none;
  height: 40px;
  outline: 0;
  font-family: "robotoregular", "robotolight", "robotomedium";
  cursor: pointer; }

.ui-button:focus {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); }

.block-heading {
  padding: 0 20px;
  font-size: 24px;
  line-height: 58px; }

.grid-6-col::after {
  content: "";
  display: table;
  clear: both; }

.grid-6-col__1-of-6 {
  display: block;
  float: left;
  width: 100%; }

.grid-6-col__1-of-6:not(:last-child) {
  margin-right: 1.02%; }

.grid-3-5-col::after {
  content: "";
  display: table;
  clear: both; }

.grid-3-5-col__1-of-3-5 {
  display: block;
  width: calc(100% / 3.5);
  float: left; }

.grid-3-5-col__2x1-of-3-5 {
  display: block;
  float: left;
  width: calc((100% - (100% / 3.5)) - 10px);
  margin-left: 10px; }

.grid-3-col::after {
  content: "";
  display: table;
  clear: both; }

.grid-3-col__1-of-3 {
  display: block;
  float: left;
  width: calc(99.9% / 3); }

.grid-3-col__1-of-3--inv-socials {
  width: calc((99.9% / 3) - 10px); }

.grid-3-col__1-of-3--inv-socials:not(:first-child) {
  margin-left: 10px; }

.grid-3-col__2-of-3 {
  display: block;
  float: left;
  width: calc(99% - (99.9% / 3)); }

.grid-3-col__3-of-3 {
  display: block;
  float: left;
  width: 100%; }

.grid-3-col__3-of-3--inv-socials {
  width: calc(100% - 10px); }

.grid-3-col__3-of-3--inv-socials:not(:first-child) {
  margin-left: 10px; }

.grid-4-col::after {
  content: "";
  display: table;
  clear: both; }

.grid-4-col__1-of-4 {
  display: block;
  float: left;
  width: calc(100% / 4); }

.grid-4-col__3-of-4 {
  display: block;
  float: left;
  width: calc(100% - (100% / 4)); }

.grid-2-col::after {
  content: "";
  display: table;
  clear: both; }

.grid-2-col__1-of-2 {
  display: block;
  float: left;
  width: 50%; }

.grid-2-col__2-of-2 {
  display: block;
  float: left;
  width: 100%; }

.clr::after {
  content: "";
  display: table;
  clear: both; }

@media (max-width: 800px) {
  .grid-3-col__1-of-3--inv-socials {
    width: 100%;
    margin: 0; }
  .grid-3-col__1-of-3--inv-socials:not(:first-child) {
    margin-left: 0;
    margin-top: 10px; } }

.more {
  cursor: pointer; }

.expandable-text {
  overflow: hidden;
  text-overflow: none;
  white-space: normal; }

.expandable-text--collapsed {
  max-height: 20px; }

.expandable-text--dots {
  text-overflow: ellipsis;
  white-space: nowrap; }

.clicktocopy {
  position: relative; }

.clicktocopy:hover + .clicktocopy__hint {
  display: block; }

.clicktocopy__hint {
  display: none;
  position: absolute;
  left: 38px;
  top: 32px;
  padding: 6px;
  width: 100px;
  text-align: center; }

.clicktocopy__hint::before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 10px;
  border-color: transparent transparent #fff transparent;
  display: block;
  width: 0;
  top: -19px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer; }

.clicktocopy__hint--green {
  background-color: #25be4a;
  color: white; }

.clicktocopy__hint--green::before {
  border-color: transparent transparent #25be4a transparent; }

.traftext {
  position: relative; }

.traftext::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left-width: 4px;
  border-left-style: solid;
  border-left-color: #f30;
  position: relative;
  right: -3px; }

.traftext--green::after {
  border-left-color: #57c176; }

.traftext--white::after {
  border-left-color: #fff; }

.traftext--px6::after {
  border-left-width: 6px; }

.helpLink {
  border-radius: 4px;
  color: white;
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: block;
  padding: 6px 20px;
  font-size: 18px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2); }

.breadcrumbs {
  padding: 5px 0px; }

.breadcrumbs__crumbs {
  display: block; }

.breadcrumbs__crumb {
  display: inline-block;
  padding-right: 15px; }

.breadcrumbs__crumb:not(:last-child)::after {
  content: "›";
  position: relative;
  right: -10px;
  font-size: 18px;
  color: #888; }

.breadcrumbs__crumb-link {
  font-size: 12px;
  color: #888; }

.breadcrumbs__crumb-link:not(.breadcrumbs__crumb-link--current):hover {
  color: #3bb35c; }

.tt__fav::after {
  content: "Add to favorites";
  position: absolute;
  width: 115px;
  bottom: -40px;
  left: -7px;
  background: white;
  border: 1px solid #ccc;
  border-left: none;
  padding: 5px;
  color: black;
  font-size: 14px;
  display: none; }

.tt__fav:hover::after {
  display: block; }

.tt-r {
  position: relative; }

/**
 * #PLACEHOLDERS
 */
.ui-layout-center__content {
  max-width: 1170px;
  margin: 0 auto; }

/**
 * #SPECIFIC
 */
.ui-accordion {
  color: #626262; }

.ui-accordion__content {
  display: none; }

.ui-accordion__header {
  padding: 15px; }

.ui-accordion__header:hover {
  background: rgba(0, 0, 0, 0.1);
  cursor: pointer; }

.ui-accordion__header span {
  position: relative; }

.ui-accordion__header span:after {
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  right: -19px;
  top: 4px;
  background: url("../images/angle-down.svg") center no-repeat;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg); }

.ui-accordion__header.ui-state--active {
  background: rgba(0, 0, 0, 0.1); }

.ui-accordion__header.ui-state--active span:after {
  -webkit-transform: none;
  -moz-transform: none;
  -o-transform: none;
  transform: none; }

.ui-accordion__header.ui-state--active + .ui-accordion__content {
  display: block; }

.ui-layout-west__footer {
  background: #f7f7f7;
  -webkit-box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.1);
  z-index: 1; }

.ui-layout-west .ui-footer__content {
  padding: 11px 0; }

.ui-footer__header {
  padding-top: 55px;
  padding-bottom: 45px; }

.ui-footer__header--green {
  background: #50c86f; }

.ui-footer--social-media {
  margin-top: 40px; }

.ui-footer--social-media div {
  width: 50px;
  height: 50px;
  display: inline-block;
  padding: 0 5px; }

.ui-footer--social-media svg {
  height: 25px;
  margin-top: 12px; }

.ui-footer--social-media svg path {
  fill: #cfcfcf;
  -webkit-transition: fill 0.3s;
  -moz-transition: fill 0.3s;
  -o-transition: fill 0.3s;
  transition: fill 0.3s; }

.ui-footer--copyright {
  padding: 20px 0; }

.stores-recommended {
  display: block;
  margin-bottom: 20px; }

.stores-recommended__rec {
  display: block;
  box-sizing: border-box;
  text-align: center;
  border: 1px solid #ccc;
  padding: 35px 5px; }

.stores-recommended__rec:hover {
  box-shadow: 0 8px 14px -6px rgba(153, 153, 153, 0.65); }

.stores-recommended__old-cashback {
  font-size: 12px;
  color: #888; }

.stores-recommended__cashback {
  font-weight: bold;
  color: #ff3300;
  font-size: 16px; }

.stores-recommended__see-all {
  font-size: 12px;
  color: #888; }

.stores-recommended__logo {
  border: none;
  margin: 0 auto; }

.stores-recommended {
  display: block;
  margin-bottom: 20px; }

.stores-recommended__rec {
  display: block;
  box-sizing: border-box;
  text-align: center;
  border: 1px solid #ccc;
  padding: 35px 5px; }

.stores-recommended__rec:hover {
  box-shadow: 0 8px 14px -6px rgba(153, 153, 153, 0.65); }

.stores-recommended__old-cashback {
  font-size: 12px;
  color: #888; }

.stores-recommended__cashback {
  font-weight: bold;
  color: #ff3300;
  font-size: 16px; }

.stores-recommended__see-all {
  font-size: 12px;
  color: #888; }

.stores-recommended__logo {
  border: none;
  margin: 0 auto; }

/**
 *Stores sidebar layout***********************************************************************************
*/
.stores-sidebar__promo {
  border: 1px solid #ccc;
  box-sizing: border-box;
  padding: 15px;
  margin-bottom: 10px; }

.stores-sidebar__categories {
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  border: 1px solid #ccc; }

.stores-sidebar__categories-list {
  list-style: none;
  margin: 0;
  padding: 0 30px;
  padding-bottom: 30px; }

.stores-sidebar__categories a {
  color: #333; }

.stores-sidebar__categories a:hover {
  color: #bbb; }

.stores-sidebar__subcategory {
  display: none; }

.stores-sidebar__subcategory--current {
  display: block; }

.stores-sidebar__subcategory--expanded {
  display: block; }

.stores-sidebar__cat-open {
  font-size: 21px;
  transition: all .5s;
  transform: rotate(0deg); }

.stores-sidebar__cat-open--checked {
  transform: rotate(90deg); }

.stores-sidebar__cat-open:hover {
  color: black; }

.stores-sidebar__logo-wrapper {
  height: 1px;
  background: #cfcfcf;
  margin: 10px 0; }

.stores-sidebar__promo-banner {
  width: 100%; }

.stores-sidebar__promo-logo {
  margin-top: 10px; }

.stores-sidebar__promo p {
  margin: 0; }

.stores-sidebar__promo a {
  color: #ff3300;
  font-size: 14px; }

.stores-sidebar__promo a:hover {
  text-decoration: underline; }

.stores-sidebar__promo a::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid #f30;
  position: relative;
  right: -3px; }

@media (max-width: 800px) {
  .stores-sidebar {
    width: 100%; }
  .stores-sidebar__promo {
    display: none; }
  .stores-sidebar__categories {
    text-align: center; } }

/**
 *Stores list layout***********************************************************************************
*/
.allstores {
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-bottom: none; }

.allstores__heading {
  margin: 15px 25px; }

.allstores__heading::after {
  content: "";
  display: table;
  clear: both; }

.allstores__heading-paragraph {
  margin: 0;
  display: inline-block;
  width: 48%; }

.allstores__arrowdown {
  position: absolute;
  top: 0;
  right: 0; }

.allstores__sorting {
  position: relative;
  display: inline-block;
  font-size: 14px;
  width: 25%;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer; }

.allstores__sorting-wrapper {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative; }

.allstores__sorting:hover .allstores__inner-list {
  display: block; }

.allstores__inner-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;
  position: absolute;
  width: calc(100% + 20px);
  left: -10px;
  padding-top: 20px;
  z-index: 1; }

.allstores__inner-list::before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 10px;
  border-color: transparent transparent #fff transparent;
  display: block;
  width: 0;
  z-index: 1;
  top: 1px;
  right: 15px; }

.allstores__inner-list::after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 11px;
  border-color: transparent transparent #ccc transparent;
  display: block;
  width: 0;
  z-index: 0;
  top: -1px;
  right: 14px; }

.allstores__inner-item {
  display: block;
  box-sizing: border-box;
  padding: 10px;
  transition: transform .3s;
  position: relative; }

.allstores__inner-item:link {
  color: black; }

.allstores__inner-item:hover {
  transform: translateX(10px); }

.allstores__inner-item-wrapper {
  border: 1px solid #ccc;
  border-top: none;
  background: white;
  position: relative; }

.allstores__inner-item-wrapper:first-child {
  border-radius: 5px 5px 0 0;
  border-top: 1px solid #ccc; }

.allstores__inner-item-wrapper:last-child {
  border-radius: 0 0 5px 5px; }

.allstores__inner-item-wrapper--checked::after {
  content: "\f00c";
  font-family: fontawesome !important;
  color: #3bb35c;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%); }

@media (max-width: 800px) {
  .allstores {
    width: 100%;
    margin-left: 0; } }

@media (max-width: 600px) {
  .allstores__sorting {
    width: 100%;
    margin: 10px 0; } }

/**
 *Sticky promo block (allstores)***********************************************************************************
*/
.promo-sticky {
  padding: 0;
  box-sizing: border-box;
  margin-bottom: 10px;
  z-index: 0; }

.promo-sticky__heading {
  border: 1px solid #ccc;
  padding: 15px; }

.promo-sticky__logo {
  max-width: 100%; }

.promo-sticky__links {
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-top: none;
  border-bottom: none; }

.promo-sticky__link-wrapper {
  box-sizing: border-box;
  border-bottom: 1px solid #ccc; }

.promo-sticky__link-wrapper:not(:nth-child(3n-1)) {
  border-right: 1px solid transparent;
  border-left: 1px solid transparent; }

.promo-sticky__link-wrapper:nth-child(3n-1) {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc; }

.promo-sticky__link {
  display: block;
  box-sizing: border-box;
  padding: 25px 15px; }

@media (max-width: 800px) {
  .promo-sticky {
    display: none; } }

/**
 *Stores block (allstores)***********************************************************************************
*/
.stores__fav-but {
  cursor: pointer;
  position: absolute;
  left: 6px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: #ccc; }

.stores__fav-but--checked {
  color: red; }

.stores__items {
  list-style: none;
  padding: 0;
  border-top: 1px solid #ccc;
  margin: 0; }

.stores__item {
  border-bottom: 1px solid #ccc;
  padding: 30px 30px;
  padding-left: 38px;
  position: relative;
  z-index: 0; }

.stores__item--paginated {
  display: none; }

.stores__item-name {
  color: #3bb35c; }

.stores__coupons {
  color: #737373;
  font-size: 14px; }

.stores__cashback {
  color: #ff3300;
  font-weight: bold; }

.stores__shop-btn {
  width: auto;
  text-align: center;
  padding: 5px 15px;
  background: #ff3300;
  border-radius: 5px;
  color: white;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  transition: background-color .5s; }

.stores__shop-btn:hover {
  background: #f54; }

@media (max-width: 800px) {
  .stores__item-name {
    width: 50%; }
  .stores__coupons {
    display: none; }
  .stores__cashback {
    display: none; } }

/**
 *Alphabet for searching in all-stores.html***********************************************************************************
*/
.alpha {
  box-sizing: border-box;
  padding: 10px 25px; }

.alpha--ww {
  background: #cfcfcf; }

.alpha--greece {
  background: #0D5EAF; }

.alpha--cyprus {
  background: #f39335; }

.alpha__filter {
  color: #36b05d;
  cursor: pointer;
  font-size: 15px;
  letter-spacing: 2px; }

.alpha__filter--greece {
  color: white; }

.alpha__filter--checked {
  color: black; }

.alpha__break {
  display: none; }

/**
 *Sorting by shipping (allstores)***********************************************************************************
*/
.sorting-shipping__value--ww {
  color: black; }

.sorting-shipping__value--cyprus {
  color: #f39335; }

.sorting-shipping__value--greece {
  color: #0D5EAF; }

/**
 *Member blocl (account)***********************************************************************************
*/
.member {
  box-sizing: border-box;
  border: 1px solid #ccc; }

.member__name {
  color: #333; }

.member__welcome {
  text-align: center;
  padding: 30px 10px 10px 20px; }

.member__lt-cb {
  color: #333;
  background: #ebebeb;
  text-align: center;
  padding: 20px; }

.member__menu-list {
  padding-top: 20px;
  padding-right: 40px;
  padding-bottom: 30px; }

.member__menu-item {
  padding-left: 38px; }

.member__menu-item--active {
  padding-left: 33px;
  border-left: 5px solid #333;
  font-weight: bold; }

.member__menu-item:last-child > .member__menu-link {
  border: none; }

.member__menu-link {
  display: block;
  padding: 13px 0 13px 0px;
  font-size: 15px;
  border-bottom: 1px solid #ccc;
  text-transform: capitalize; }

@media (max-width: 800px) {
  .member__menu-item--active {
    border-left: none; } }

@media (max-width: 1000px) {
  .member {
    width: 100%; } }

/**
 *Dashboard block (allstores)***********************************************************************************
*/
.dashboard__balance {
  text-align: center; }

.dashboard__balance-wrapper {
  box-sizing: border-box;
  margin: 20px 0; }

.dashboard__wh-cb {
  margin: 20px 40px; }

.dashboard__heading-bonus {
  background: #43aed1;
  color: white;
  cursor: pointer; }

.dashboard__notification {
  color: white;
  cursor: pointer; }

.dashboard__notification--bonus {
  background: #43aed1; }

.dashboard__notification--double-cb {
  background: #25be4a; }

.dashboard__fav-item-wrapper {
  position: relative; }

.dashboard__fav-add {
  position: absolute;
  top: 20px;
  left: 10px;
  color: #737373;
  cursor: pointer; }

.dashboard__fav-add:hover {
  color: red; }

.dashboard__fav-add--added {
  color: red; }

.dashboard__fav-item:nth-child(odd) {
  border-right: 1px solid #ccc; }

.dashboard__fav-link {
  display: block;
  padding: 15px; }

.dashboard__fav-logo {
  margin-left: 30px; }

.dashboard__trips-table {
  width: 100%; }

.dashboard__trips-table table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px; }

.dashboard__trips-table tr:nth-child(2) td {
  padding: 10px 10px 5px 10px; }

.dashboard__trips-table td {
  padding: 5px 10px; }

.dashboard__trips-table th {
  text-align: left;
  padding: 5px 10px;
  font-weight: 400; }

.dashboard__th-date {
  width: 20%; }

.dashboard__th-store {
  width: 40%; }

.dashboard__th-cb {
  width: 20%; }

.dashboard__th-st {
  width: 20%; }

.dashboard__coupon {
  padding: 30px; }

.dashboard__clogo-wrapper {
  float: left;
  width: 20%; }

.dashboard__ccontent {
  float: left;
  width: 60%; }

.dashboard__cdescription {
  position: relative; }

.dashboard__c-read-more {
  position: relative;
  right: -80%;
  font-weight: bold;
  background: url(../images/trans-white-grad-60x1.png) repeat-y 0 0; }

.dashboard__shop-btn {
  padding: 5px 25px; }

.dashboard__code-expires {
  position: relative; }

.dashboard__fav-found {
  position: absolute;
  bottom: 10px;
  background: white;
  width: calc(100% - 45px); }

.dashboard__fav-found-items {
  position: absolute;
  background: white;
  display: block;
  width: 100%; }

.dashboard__fav-found-item:hover {
  background: rgba(140, 140, 140, 0.3); }

.dashboard__m {
  display: none; }

@media (max-width: 1000px) {
  .dashboard {
    width: 100%;
    margin-left: 0;
    margin-top: 10px; } }

@media (max-width: 599px) {
  .dashboard__balance-wrapper {
    width: 100%;
    border-right: none; }
  .dashboard__wh-cb-wrapper {
    width: 100%;
    text-align: center; } }

@media (max-width: 800px) {
  .dashboard__ccontent {
    width: 100%; }
  .dashboard__fav-item {
    width: 100%; }
  .dashboard__shop-btn {
    float: right; }
  .dashboard__d {
    display: none; }
  .dashboard__m {
    display: block; } }

/**
 *Input text with button (account)***********************************************************************************
*/
.input-t-b {
  position: relative; }

/**
 *Invite page layout (invite)***********************************************************************************
*/
.invite__heading {
  margin-bottom: 20px; }

.invite__form {
  width: 76.0683%;
  margin: 0 auto;
  margin-top: 40px; }

.invite__email-wrapper {
  position: relative; }

.invite__input-text {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px; }

.invite__input-text--email {
  width: 67.6404%; }

.invite__input-text--reflink {
  width: 100%;
  padding-right: 100px; }

.invite__input-text--active {
  border-color: #57c176; }

.invite__email-btn {
  width: calc(99% - 67.6504%);
  color: white;
  text-transform: capitalize;
  border-radius: 0 5px 5px 0;
  border: none;
  background: #25be4a;
  padding: 11px;
  position: relative;
  left: -10px;
  cursor: pointer; }

.invite__email-btn:disabled {
  background: #ccc;
  cursor: unset; }

.invite__share {
  margin-top: 20px;
  margin-bottom: 40px; }

.invite__socials {
  width: 76.0683%;
  margin: 0 auto; }

.invite__copylink {
  position: relative; }

.invite__greentext-btn {
  position: absolute;
  right: 20px;
  top: 0;
  font-size: 16px;
  border: none;
  background: none;
  color: #36b05d;
  cursor: pointer; }

.invite__placeholder {
  padding: 8px;
  border-radius: 3px;
  text-align: center;
  font-size: 18px;
  color: white; }

.invite__placeholder--fb {
  background: #4267b2; }

.invite__placeholder--tw {
  background: #1da1f2; }

.invite__placeholder--email {
  background: #25be4a; }

.invite__placeholder--sms {
  background: #333; }

.invite__placeholder--gp {
  background: #db4437; }

.invite__soc-icon {
  padding-top: 3px;
  padding-left: 10px;
  float: left;
  color: white; }

.invite__tab {
  cursor: pointer;
  padding: 15px 0;
  border: 1px solid #ccc;
  font-size: 18px; }

.invite__tab:not(:last-child) {
  border-right: none; }

.invite__tab--active {
  font-weight: bold;
  border-top: 5px solid #25be4a;
  border-bottom: none;
  color: #25be4a; }

.invite__tabcontent-wrapper {
  width: 99.9%; }

.invite__tabcontent {
  display: none; }

.invite__tabcontent--active {
  display: block; }

.invite__tabcontent-table {
  width: 75%;
  margin: 0 auto;
  padding: 10px 50px; }

.invite__heading--socials-m {
  display: none; }

.invite__m {
  display: none; }

.invite__ref-stats {
  text-align: center;
  margin: 20px 0; }

.invite__ref-stat {
  display: inline-block;
  margin-right: 15px; }

.invite__ref-val {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 5px;
  color: white; }

.invite__ref-val--clicks {
  background: grey; }

.invite__ref-val--referrals {
  background: lightblue; }

.invite__ref-val--pending {
  background: orange; }

.invite__ref-val--earnings {
  background: lightgreen; }

.invite__conditions {
  padding: 10px;
  margin-top: 15px; }

.invite__ref-but {
  text-align: center;
  padding-left: 50px; }

.invite__row-inv {
  display: none; }

.invite__row-su {
  display: none; }

.invite__row-ql {
  display: none; }

@media (max-width: 800px) {
  .invite__promo {
    display: none; }
  .invite__form {
    display: none; }
  .invite__heading--socials-d {
    display: none; }
  .invite__heading--socials-m {
    display: block;
    margin-bottom: 15px; }
  .invite__referrals {
    margin-top: 20px; }
  .invite__tabcontent-table {
    width: 90%;
    padding: 10px 0; }
  .invite__m {
    display: block; }
  .invite__d {
    display: none; }
  .invite__how-num {
    color: #4990e2;
    font-size: 24px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #4990e2;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 10px; }
  .invite__terms {
    width: 100%;
    text-align: center; } }

/**
 *Modal window blocks (all)***********************************************************************************
*/
.modal {
  display: none; }

.modal--active {
  display: block; }

.modal__caption {
  background: #f1f0ee;
  border-radius: 2px;
  padding: 20px 30px;
  padding-left: 50px;
  margin: 25px 0; }

.modal__cap-error {
  font-weight: bold;
  position: relative; }

.modal__capicon {
  position: absolute;
  font-size: 33px;
  left: -40px; }

.modal__close-btn {
  position: absolute;
  top: -30px;
  right: 0px;
  font-size: 22px;
  background: none;
  border: none;
  cursor: pointer;
  color: white; }

.modal__label {
  font-weight: bold; }

.modal__input-t {
  padding: 8px 10px;
  border-radius: 4px;
  width: 90%;
  border: 1px solid #ccc;
  margin-bottom: 15px;
  margin-top: 10px; }

.modal__input-t--help {
  border-bottom-width: 2px;
  width: 300px; }

.modal__input-select {
  padding: 8px 10px;
  width: 300px;
  border: 1px solid #ccc;
  margin-bottom: 15px;
  margin-top: 10px; }

.modal__textarea {
  border: 1px solid #ccc;
  resize: none;
  padding: 10px;
  margin-bottom: 15px;
  margin-top: 10px; }

.modal__link {
  color: #57c176;
  cursor: pointer; }

.modal__link:hover {
  text-decoration: underline; }

.modal__btn {
  display: inline-block;
  background: none;
  border: none;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  margin: 15px 0;
  font-size: 18px;
  padding: 0 35px; }

.modal__overlay {
  position: fixed;
  top: 0;
  width: 100vw;
  left: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 11; }

.modal__overlay--shown {
  display: block; }

.overlay {
  display: none;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9998; }

.overlay--shown {
  display: block; }

.modal-email-prev {
  position: fixed;
  display: none;
  z-index: 10000;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  width: 640px;
  height: 450px;
  background: white url(../images/email-preview.1.0.0.jpg) no-repeat;
  background-position-y: bottom; }

.modal-email-prev--shown {
  display: block; }

.modal-shown {
  display: block; }

@media (max-width: 480px) {
  .modal__input-t {
    width: 90%; }
  .modal__input-select {
    width: 90%; }
  .modal__textarea {
    width: 90%; }
  .modal__close-btn {
    right: 20px; } }

.modal-coupon-login {
  display: none;
  position: absolute;
  z-index: 9999;
  background: white;
  width: 760px;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  margin-top: 50px;
  padding: 35px; }

.modal-coupon-login__socials {
  float: right;
  margin-top: 30px; }

.modal-coupon-login__social {
  display: inline-block;
  color: white;
  padding: 8px 10px;
  min-width: 260px;
  border-radius: 4px; }

.modal-coupon-login__social:not(:last-child) {
  margin-bottom: 15px; }

.modal-coupon-login__social--fb {
  background: #4267b2; }

.modal-coupon-login__social--gp {
  background: #db4437; }

.modal-coupon-login__socicon {
  color: white;
  display: inline-block;
  width: 25px;
  font-size: 18px; }

.modal-coupon-login__or {
  display: none;
  border: 3px solid #d1cec7;
  padding: 10px 15px;
  border-radius: 50%;
  position: absolute;
  top: 56%;
  font-size: 18px; }

.modal-coupon-login__without-cb {
  font-size: 22px;
  float: right; }

@media (max-width: 1000px) {
  .modal-coupon-login {
    width: 100%;
    position: absolute; }
  .modal-coupon-login__or {
    display: none; } }

@media (max-width: 650px) {
  .modal__login-form {
    width: 100%;
    text-align: center; }
  .modal-coupon-login__socials {
    width: 100%;
    text-align: center; } }

.modal-help-email {
  display: none;
  position: absolute;
  z-index: 9999;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 35px;
  width: 760px; }

@media (max-width: 750px) {
  .modal-help-email {
    width: 100%; } }

/**
 *Login - sign up forms (login.html)***********************************************************************************
*/
.login__m {
  display: none; }

.login__tabs::after {
  content: "";
  display: table;
  clear: both; }

.login__tab {
  width: 50%;
  float: left;
  cursor: pointer;
  text-align: center;
  background: #cfcfcf;
  color: #000;
  padding: 10px 0; }

.login__tab--active {
  color: white;
  background: #25be4a;
  box-shadow: inset 0px -3px 0px #1d9339;
  font-weight: bold;
  font-size: 17px; }

.login__forms {
  padding: 0 140px; }

.login__forms::after {
  content: "";
  display: table;
  clear: both; }

.login__form-wrapper {
  width: 400px;
  min-height: 580px;
  border-style: solid;
  border-width: 2px;
  border-top: none;
  border-radius: 10px;
  position: relative; }

.login__form-wrapper--signup {
  border-color: #43aed1; }

.login__form-wrapper--login {
  border-color: #25be4a; }

.login__form {
  padding-bottom: 0; }

.login__form-heading {
  font-size: 16px;
  padding: 10px 15px;
  border-radius: 10px 10px 0 0;
  background: #25be4a;
  color: white; }

.login__form-heading--signup {
  background: #43aed1; }

.login__form-fields {
  text-align: center;
  padding: 0 50px; }

.login__fb {
  display: block;
  width: 250px;
  height: 35px;
  margin: 0 auto;
  background: url(../images/continue-with-facebook.1.0.0.png) no-repeat; }

.login__or-box {
  position: relative;
  border-top: 1px solid black;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 20px; }

.login__or-text {
  display: inline-block;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 0 10px; }

.login__input-t {
  width: 100%;
  margin-top: 15px;
  padding: 5px 5px;
  border: 1px solid #e0e0e0;
  box-shadow: inset 2px 2px 5px #eaeaea; }

.login__submit-btn {
  border: none;
  border-radius: 5px;
  font-size: 16px;
  padding: 5px 10px;
  color: #fff;
  background: #f87628;
  cursor: pointer;
  margin-top: 15px;
  min-width: 135px; }

.login__agreement {
  border-radius: 0 0 5px 5px;
  text-align: center;
  font-size: 12px;
  background: #cfcfcf;
  padding: 5px 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%; }

@media (max-width: 1080px) {
  .login__form-wrapper {
    width: 100%;
    display: none; }
  .login__form-wrapper--active {
    display: block; }
  .login__tabs {
    margin-bottom: 20px; }
  .login__m {
    display: block; }
  .login__fb {
    background: url(../images/continue-with-facebook.1.0.0.png) no-repeat; }
  .login__fb-m {
    display: none; } }

@media (max-width: 800px) {
  .login__m {
    display: block; }
  .login__d {
    display: none; }
  .login__forms {
    padding: 0; }
  .login__form-wrapper {
    width: 100%;
    display: none;
    border: none; }
  .login__form-wrapper--active {
    display: block; }
  .login__fb {
    background-image: none;
    border-radius: 5px;
    background-color: #4267b2;
    width: 100%;
    height: auto; }
  .login__form-fields {
    padding: 0 10px; }
  .login__or-box {
    border: none;
    padding-bottom: 50px; }
  .login__or-text {
    border: 1px solid gold;
    border-radius: 50%;
    padding: 10px;
    margin-top: 15px; }
  .login__submit-btn {
    width: 100%; }
  .login__input-t {
    border-radius: 5px;
    padding: 10px; }
  .login__input-t:focus {
    border-color: #66afe9;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); } }

/**
 *Help block (help.html)***********************************************************************************
*/
.help {
  margin-top: 20px; }

.help__topics {
  border: 1px solid #ccc;
  margin-top: 20px;
  padding-bottom: 20px; }

.help__topic {
  cursor: pointer;
  display: inline-block;
  margin-top: 10px;
  padding: 5px 10px;
  color: rgba(41, 51, 61, 0.9);
  font-weight: 600; }

.help__exp {
  display: inline-block;
  background: #29333d;
  color: white;
  border-radius: 50%;
  padding: 4px 6px;
  transition: all .5s; }

.help__exp--open {
  background: #57c176; }

.help__subtopics {
  display: none; }

.help__subtopic {
  margin-top: 15px; }

.help__subtopic-question {
  cursor: pointer;
  padding: 10px 20px;
  background-color: #f7f7f7;
  color: rgba(41, 51, 61, 0.75);
  margin: 0 10px;
  border-radius: 5px; }

.help__subtopic-question--expanded {
  border-bottom: 1px solid #ccc;
  border-radius: 5px 5px 0 0;
  font-weight: 600; }

.help__sub-exp {
  transition: all .5s;
  font-size: 26px;
  float: right; }

.help__sub-exp--open {
  transform: rotateX(180deg);
  color: #57c176; }

.help__subtopic-definition {
  display: none;
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #f7f7f7;
  color: rgba(41, 51, 61, 0.75); }

.help__subtopic-definition:last-child {
  border-radius: 0 0 5px 5px; }

.help__sidebar {
  padding: 20px;
  border: 1px solid #ccc;
  margin-bottom: 20px; }

@media (max-width: 800px) {
  .help {
    padding: 20px; } }

/**
 *Universal templating class (*.html)***********************************************************************************
*/
.container {
  width: 1170px;
  margin: 0 auto; }

.container::after {
  content: "";
  display: table;
  clear: both; }

.sidebar {
  display: block;
  width: calc(100% / 3.5);
  float: left; }

.sidebar--help {
  margin-top: 158px; }

@media (max-width: 800px) {
  .sidebar {
    width: 100%; } }

.s-content {
  display: block;
  float: left;
  width: calc((100% - (100% / 3.5)) - 10px);
  margin-left: 10px; }

@media (max-width: 800px) {
  .s-content {
    width: 100%;
    margin-left: 0; } }

.coupon {
  padding: 20px 30px;
  border-bottom: 1px solid #ccc; }

.coupon::after {
  content: "";
  display: table;
  clear: both; }

.coupon--stores {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc; }

.coupon--stores:first-child {
  border-top: 1px solid #ccc; }

.coupon__type {
  text-transform: uppercase;
  color: white;
  padding: 0 2px;
  font-size: 13px; }

.coupon__type--promocode {
  background: #ff2552; }

.coupon__type--sale {
  background: #0394ea; }

.coupon__type--printable {
  background: #8f13fd; }

.coupon__times-used {
  font-size: 13px;
  color: #29333d; }

.coupon__logo-wrapper {
  float: left;
  width: 20%;
  position: relative;
  top: 30px; }

.coupon__content {
  float: left;
  width: 60%; }

.coupon__description {
  position: relative; }

.coupon__c-read-more {
  position: relative;
  right: -80%;
  font-weight: bold;
  background: url(../images/trans-white-grad-60x1.png) repeat-y 0 0; }

.coupon__shop-btn {
  padding: 5px 10px;
  color: white;
  position: relative;
  top: 50px;
  left: 20px; }

.coupon__code-expires {
  position: relative; }

.coupon--paginated {
  display: none; }

.coupon__modal {
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0; }

.coupon__modal-window {
  width: 640px;
  background: white;
  position: absolute;
  z-index: 12;
  top: 140px;
  left: 50%;
  transform: translateX(50%); }

.coupon__modal-window--stores {
  transform: translateX(-15%); }

.coupon__modal-header {
  padding: 10px 0; }

.coupon__modal-logo {
  width: 100px;
  position: relative;
  top: 5px; }

.coupon__modal-code {
  text-align: center;
  margin: 0 120px;
  margin-top: 40px;
  padding: 15px 10px;
  border: 2px dotted orangered;
  background: rgba(255, 69, 0, 0.1);
  position: relative;
  margin-bottom: 15px; }

.coupon__modal-code--qr {
  margin-left: 0;
  margin-right: 0; }

.coupon__modal-code-text {
  word-wrap: break-word;
  font-size: 22px; }

.coupon__modal-copy-btn {
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: #25be4a;
  border: none;
  border-radius: 4px;
  color: white;
  padding: 7px;
  font-weight: bold;
  cursor: pointer; }

.coupon__modal-footer {
  text-align: right;
  background: #cfcfcf;
  padding: 5px 20px;
  margin-top: 20px; }

@media (max-width: 1150px) {
  .coupon__logo-wrapper {
    width: 100%;
    text-align: center;
    top: 0; }
  .coupon__content {
    width: 100%; }
  .coupon__shop-btn {
    float: right;
    width: 100%;
    text-align: center;
    margin-top: 10px;
    top: 0;
    left: 0; } }

@media (max-width: 1000px) {
  .coupon__modal {
    width: 100%; }
  .coupon__modal-window {
    left: 0;
    transform: none;
    width: 100%;
    padding-right: 0; }
  .coupon__modal-code {
    margin: 20px 10px; } }

.pagination {
  padding-top: 20px;
  text-align: center; }

.pagination__button {
  font-size: 32px;
  display: inline-block;
  cursor: pointer;
  background: none;
  border: none; }

.pagination__button:hover:not(:disabled) {
  color: #57c176; }

.user-block {
  position: relative;
  display: inline-block;
  float: right; }

.user-block::after {
  content: "";
  display: table;
  clear: both; }

.user-block:hover .user-block__menu {
  display: block; }

.user-block__menu {
  display: none;
  position: absolute;
  z-index: 9999;
  right: 0;
  top: 0;
  width: 270px;
  font-size: 13px;
  padding: 10px 20px;
  padding-top: 55px; }

.user-block__menu::before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 10px;
  border-color: transparent transparent #fff transparent;
  display: block;
  width: 0;
  z-index: 1;
  top: 36px;
  right: 37px; }

.user-block__menu::after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 11px;
  border-color: transparent transparent #ccc transparent;
  display: block;
  width: 0;
  z-index: 0;
  top: 34px;
  right: 36px; }

.user-block__cbalance {
  color: #57c176;
  font-weight: bold; }

.user-block__item-wrapper {
  display: block;
  background: white;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc; }

.user-block__item-wrapper:first-child {
  border-top: 1px solid #ccc;
  padding-top: 10px; }

.user-block__item-wrapper:last-child {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px; }

.user-block__item-wrapper--socials {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 10px 15px; }

.user-block__menu-link {
  display: block;
  padding: 5px 15px; }

.user-block__menu-link:hover {
  color: #57c176; }

.user-block__connect-link {
  display: inline-block;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  width: 49%; }

.user-block__connect-link--fb {
  background: #4267b2; }

.user-block__connect-link--gp {
  background: #db4437; }

.item__readmore {
  position: absolute;
  bottom: 5px;
  right: 25%;
  font-size: 13px;
  padding: 5px;
  font-weight: bold;
  z-index: 9; }

@media (max-width: 1010px) {
  .item__readmore {
    left: 0; } }

.stores-reviews {
  margin: 15px 0;
  padding: 10px; }

.stores-reviews__heading {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px; }

.stores-reviews__leave {
  color: #888;
  font-size: 13px;
  cursor: pointer; }

.stores-reviews__leave:hover {
  text-decoration: underline; }

.stores-reviews__review {
  border-bottom: 1px solid #ccc;
  margin: 15px 0; }

.stores-reviews__rate {
  font-size: 12px;
  color: grey; }

.stores-reviews__rate--1 .stores-reviews__star--1 {
  color: gold; }

.stores-reviews__rate--2 .stores-reviews__star--1, .stores-reviews__rate--2 .stores-reviews__star--2 {
  color: gold; }

.stores-reviews__rate--3 .stores-reviews__star--1, .stores-reviews__rate--3 .stores-reviews__star--2, .stores-reviews__rate--3 .stores-reviews__star--3 {
  color: gold; }

.stores-reviews__rate--4 .stores-reviews__star--1, .stores-reviews__rate--4 .stores-reviews__star--2, .stores-reviews__rate--4 .stores-reviews__star--3, .stores-reviews__rate--4 .stores-reviews__star--4 {
  color: gold; }

.stores-reviews__rate--5 .stores-reviews__star--1, .stores-reviews__rate--5 .stores-reviews__star--2, .stores-reviews__rate--5 .stores-reviews__star--3, .stores-reviews__rate--5 .stores-reviews__star--4, .stores-reviews__rate--5 .stores-reviews__star--5 {
  color: gold; }

.stores-reviews__username {
  color: #888;
  font-weight: bold; }

.stores-reviews__date {
  font-weight: bold;
  font-size: 14px;
  color: #888; }

.stores-reviews__description {
  margin: 10px 0; }

.stores-reviews__pag-link {
  display: inline-block;
  border: 1px solid #ccc;
  padding: 2px 8px; }

.stores-reviews__pag-link:hover {
  color: white;
  background: #25be4a; }

.stores-reviews__pag-link--current {
  color: white;
  background: #25be4a; }

.stores-reviews__pag-link--disabled {
  color: #888;
  background: #cfcfcf; }

.stores-reviews__pag-link--disabled:hover {
  color: #888;
  background: #cfcfcf; }

.balance__wrapper {
  margin-top: 20px;
  position: relative; }

.balance__table {
  border-collapse: collapse;
  margin: 0 auto;
  margin-top: 15px; }

.balance__table-row {
  border-bottom: 1px solid #ccc; }

.balance__table-row:first-child {
  border-top: 1px solid #ccc; }

.balance__table-row--available {
  background: #f5f5f5; }

.balance__table-row--pending {
  background: #fcf8e3; }

.balance__table-row--declined {
  background: #dff0d8; }

.balance__table-row--requested {
  background: #d9edf7; }

.balance__table-row--proceeded {
  background: #f2dede; }

.balance__table-row--lifetime {
  background: #ffffff;
  font-weight: bold; }

.balance__cname {
  padding: 10px 5px;
  width: 90%; }

.balance__cvalue {
  padding: 5px; }

.balance__withdraw {
  font-size: 22px;
  text-align: center;
  position: relative;
  top: 100px; }

.balance__withdraw-btn {
  height: 60px;
  font-size: 25px;
  font-weight: bold;
  border-radius: 4px;
  color: white;
  margin-top: 10px; }

@media (max-width: 1000px) {
  .balance__wrapper {
    width: calc(100% - 10px); } }

@media (max-width: 650px) {
  .balance {
    padding: 10px; }
  .balance__wrapper {
    width: 100%;
    margin-left: 0; }
  .balance__table-wrapper {
    width: 100%; }
  .balance__withdraw {
    width: 100%;
    top: 0; } }

.history {
  margin-top: 15px; }

.history__table {
  width: 100%;
  text-align: center;
  margin-top: 15px; }

.history__theading {
  cursor: pointer; }

.history__tcell {
  padding: 5px; }

.forgot {
  padding: 20px;
  text-align: center; }

.forgot__input-t {
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin: 10px 0;
  font-size: 18px; }

.forgot__input-t:focus {
  border-color: #0D5EAF; }

.forgot__label {
  text-decoration: underline; }

.forgot__refresh-btn {
  background: none;
  border: none;
  cursor: pointer; }

.forgot__submit-btn {
  border-radius: 4px;
  color: #fff;
  font-size: 18px;
  margin-top: 20px; }

.flag-drop {
  cursor: pointer;
  text-align: right;
  width: 70px;
  font-size: 14px;
  position: absolute;
  top: 10px;
  left: 190px;
  background: white;
  z-index: 1; }

.flag-drop__list {
  border: 1px solid #b3b3b3;
  display: none;
  padding: 5px; }

.flag-drop__item {
  padding-right: 19px; }

.flag-drop__item--gr {
  background: url(../images/flags/gr.png) no-repeat; }

.flag-drop__item--cy {
  background: url(../images/flags/cy.png) no-repeat; }

.flag-drop__selected {
  padding: 5px;
  background-position: 5px center;
  background-repeat: no-repeat; }

.flag-drop__selected--gr {
  background-image: url(../images/flags/gr.png); }

.flag-drop__selected--cy {
  background-image: url(../images/flags/cy.png); }

.flag-drop__arrow {
  background: url(../images/flags/arrow.png) no-repeat;
  display: inline-block;
  width: 15px;
  height: 15px; }

.flag-drop__hidden {
  display: none !important; }

.ui-dialog__content {
  background: #fff;
  width: 100%;
  max-width: 370px;
  margin: 0 auto; }

.ui-dialog__header {
  background: #f7f7f7;
  padding: 6px 0; }

.ui-inputfield--dialog {
  -webkit-transition: border-color 0.3s ease, box-shadow 0.3s ease;
  -moz-transition: border-color 0.3s ease, box-shadow 0.3s ease;
  -o-transition: border-color 0.3s ease, box-shadow 0.3s ease;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid #919191;
  line-height: 22px;
  font-size: 14px;
  padding: 4px;
  padding-left: 10px;
  outline: medium none;
  height: 22px; }

.ui-inputfield--dialog:focus {
  border: 1px solid #6CA5D3;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.18);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.18); }

.input-text {
  border: 1px solid #ccc;
  padding: 5px;
  padding-right: 30px;
  background: #f7f7f7; }

.ui-search-column {
  padding: 0 85px; }

.ui-layout-west__footer td {
  text-align: center; }

.ui-panelgrid--banner-price a {
  color: #3bb35c; }

.ui-panelgrid--banner-price .ui-first {
  padding-right: 35px; }

.ui-panelgrid--banner-price .ui-last {
  padding-left: 35px; }

.ui-panelgrid--banner-price .ui-panelgrid__cell {
  padding-top: 22px;
  padding-bottom: 22px; }

.ui-panelgrid--logos {
  height: 67px;
  margin-top: 20px; }

.ui-panelgrid__cell--melinamay {
  background: url("../images/melinamay-logo.png") left no-repeat;
  background-size: auto 100%; }

.ui-panelgrid__cell--ebay {
  background: url("../images/ebay-logo.png") left no-repeat;
  background-size: auto 100%; }

.ui-panelgrid__cell--aliexpress {
  background: url("../images/aliexpress-logo.png") left no-repeat;
  background-size: auto 100%; }

.ui-panelgrid__cell--aegean {
  background: url("../images/aegean-logo.png") left no-repeat;
  background-size: auto 100%; }

.ui-panelgrid__cell--shopgr {
  background: url("../images/shopgr-logo.png") left no-repeat;
  background-size: auto 100%; }

.ui-panelgrid__cell--deliverasgr {
  background: url("../images/deliverasgr-logo.png") left no-repeat;
  background-size: auto 100%; }

.ui-panelgrid__cell--zackret {
  background: url("../images/zackret-logo.png") left no-repeat;
  background-size: auto 100%; }

.ui-panelgrid--col-3 td {
  width: 33.33%;
  padding: 0 20px;
  vertical-align: top; }

.ui-panelgrid--basic {
  vertical-align: top; }

.ui-panelgrid__image--compass {
  background-image: url("../images/icon-compass.png"); }

.ui-panelgrid__image--cart {
  background-image: url("../images/icon-cart.png"); }

.ui-panelgrid__image--refund {
  background-image: url("../images/icon-refund.png"); }

.ui-panelgrid--col-4 td {
  width: 25%; }

.ui-panelgrid--borders td {
  padding: 0 15px;
  box-sizing: border-box; }

.ui-panelgrid--borders td:first-child {
  padding-left: 0; }

.ui-panelgrid--borders td:last-child {
  padding-right: 0; }

.ui-panelgrid--borders td > div {
  border: 1px solid #eee; }

.ui-panelgrid__background {
  height: 190px;
  width: 268px; }

.ui-panelgrid__background > div {
  display: block;
  margin: 0 auto;
  width: 160px;
  padding-top: 10px; }

.ui-panelgrid__background--pink {
  background: #e96475; }

.ui-panelgrid__background--green {
  background: #b9e986; }

.ui-panelgrid__background--blue {
  background: #4990e2; }

.ui-panelgrid__background--purple {
  background: #8f13fd; }

.ui-panelgrid__background--logo {
  background: center center / auto no-repeat; }

.ui-panelgrid__background--market {
  background-image: url("../images/market-logo.png"); }

.ui-panelgrid__background--bonton {
  background-image: url("../images/bonton-logo.png"); }

.ui-panelgrid__background--robot {
  background-image: url("../images/robot-logo.png"); }

.ui-panelgrid__background--nike {
  background-image: url("../images/nike-logo.png"); }

.ui-panelgrid--testemonials table td {
  vertical-align: middle;
  padding: 0; }

.ui-panelgrid--testemonials table td > div {
  border: 0; }

.ui-panelgrid--testemonials .ui-panelgrid__subheader, .ui-panelgrid--testemonials .ui-panelgrid__output {
  padding-left: 23px; }

.ui-panelgrid--sitemap {
  margin-top: 30px; }

.ui-panelgrid--sitemap td {
  vertical-align: top; }

.ui-panelgrid--footer-article {
  margin-top: 25px; }

.ui-panelgrid--footer-article td:first-child {
  width: 70px; }

.ui-panelgrid--footer-article td:last-child {
  padding-left: 20px; }

.ui-panelgrid--layout td {
  vertical-align: top; }

.ui-panelgrid--layout .ui-panelgrid__cell--ad {
  width: 300px; }

.ui-panelgrid--borders-10 td {
  padding: 0 5px;
  box-sizing: border-box; }

.ui-panelgrid--borders-10 td:first-child {
  padding-left: 0; }

.ui-panelgrid--borders-10 td:last-child {
  padding-right: 0; }

.ui-panelgrid--borders-10 td > div {
  border: 1px solid #eee;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08); }

.ui-button.ui-button--orange {
  background: #f87628;
  color: #fff; }

.ui-button.ui-button--orange:hover, .ui-button.ui-button--orange:focus {
  background: #faa572; }

.ui-button.ui-button--orange:active {
  background: #cd5107; }

.ui-button.ui-button--green {
  background: #25be4a; }

.ui-button.ui-button--green:hover, .ui-button.ui-button--green:focus {
  background: #52dd74; }

.ui-button.ui-button--green:active {
  background: #197e31; }

.ui-button.ui-button--red {
  background: #ff3300;
  color: white; }

.ui-button.ui-button--red:hover, .ui-button.ui-button--red:focus {
  background: #ff704d; }

.ui-button.ui-button--red:active {
  background: #b32400; }

.ui-button--search {
  position: absolute;
  right: 0;
  top: -1px;
  width: 80px;
  -moz-border-radius-topright: 5px;
  -webkit-border-top-right-radius: 5px;
  border-top-right-radius: 5px;
  -moz-border-radius-bottomright: 5px;
  -webkit-border-bottom-right-radius: 5px;
  border-bottom-right-radius: 5px; }

.ui-button--left {
  margin-right: 15px; }

.ui-button--right {
  margin-left: 15px; }

.ui-button--banner {
  width: 100%;
  background: #4ec96e;
  color: #fff;
  border: 2px solid #32b452;
  height: 50px;
  max-width: 400px; }

.ui-button--banner:hover, .ui-button--banner:focus {
  background: #60d37c; }

.ui-button--banner:active {
  background: #32b452; }

.ui-button--orange-light {
  background: #fce5d3;
  border: 1px solid #b78d65;
  color: #de804d;
  height: 32px; }

.ui-button--orange-light:hover, .ui-button--orange-light:focus {
  background: white; }

.ui-button--orange-light:active {
  background: #f7bb8b; }

.ui-button--green-light {
  background: #cef4dd;
  border: 1px solid #599f69;
  color: #36b05d;
  height: 32px; }

.ui-button--green-light:hover, .ui-button--green-light:focus {
  background: white; }

.ui-button--green-light:active {
  background: #90e6b2; }

.ui-button--white {
  background: #fff;
  border: 1px solid #7aae88; }

.ui-button--white:hover, .ui-button--white:focus {
  background: #d9d9d9; }

.ui-button--white:active {
  background: #7aae88; }

.ui-button--footer-header {
  width: 170px;
  height: 50px;
  margin-top: 45px; }

.ui-button--navigation {
  display: none;
  width: 24px;
  height: 24px;
  padding: 0;
  margin-right: 15px; }

.ui-button--item {
  position: absolute;
  right: 17px;
  top: -moz-calc(50% - 20px);
  top: -webkit-calc(50% - 20px);
  top: calc(50% - 20px); }

.content .ui-button {
  height: 34px; }

.ui-button--ref-btn {
  display: inline-block;
  padding: 10px 70px;
  color: white;
  height: auto;
  border-radius: 3px; }

.ui-button--inputbtn {
  height: 27px;
  font-size: 16px;
  border-radius: 3px;
  border: none;
  color: white;
  padding: 5px 10px;
  position: absolute;
  right: 0px;
  top: 20px; }

.ui-panel__banner {
  background: url("../images/banner-bg.png") no-repeat;
  min-height: 400px;
  margin-top: -2px; }

.ui-panel--all-stores {
  border: 1px solid #e0e0e0; }

.ui-panel--all-stores .ui-panel__header {
  background: url("../images/banner-allstores.jpg") 0 100% no-repeat;
  height: 150px;
  background-size: 100% auto;
  position: relative; }

.ui-panel--all-stores .ui-panel__header:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.2)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 ); }

.ui-panel--all-stores .ui-panel__content {
  padding: 1em 1em 0 1em; }

.ui-panel--favorite {
  border: 1px solid #e0e0e0;
  margin-right: 1em;
  margin-top: 1em;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08); }

.ui-panel--favorite .ui-panel__header {
  padding: 1em; }

.ui-panel--favorite .ui-panel__content {
  padding: 1em;
  padding-top: 0; }

.ui-panel__content.ui-overflow--hidden {
  margin-bottom: 1em; }

.ui-separator {
  color: #1e1e1e;
  font-family: "robotomedium"; }

.ui-separator--vertical {
  width: 0;
  border-right: 1px solid #e0e0e0;
  position: absolute;
  height: 44px;
  top: 50%;
  right: -1px;
  margin-top: -22px; }

.ui-separator--margin {
  margin-top: 50px;
  margin-bottom: 30px; }

.ui-separator .ui-commandlink {
  font-size: 19px;
  float: right;
  color: #737373;
  line-height: 39px; }

.ui-commandlink--gray {
  color: #737373; }

.ui-commandlink--facebook-login {
  width: 300px;
  height: 36px;
  background: url("../images/fb-signin.gif") no-repeat;
  display: block;
  margin: 0 auto;
  background-size: cover; }

.ui-panelgrid--sitemap .ui-commandlink {
  display: block;
  line-height: 30px;
  font-size: 15px;
  -webkit-transition: color 0.5s;
  -moz-transition: color 0.5s;
  -o-transition: color 0.5s;
  transition: color 0.5s; }

.ui-panelgrid--sitemap .ui-commandlink:hover {
  color: #fff; }

.ui-commandlink--social-media {
  display: block;
  background: #777;
  -webkit-transition: background 0.3s;
  -moz-transition: background 0.3s;
  -o-transition: background 0.3s;
  transition: background 0.3s; }

.ui-commandlink--social-media:hover {
  background: #fff; }

.ui-commandlink--social-media:hover path {
  fill: #000; }

.ui-commandlink--social-media-dark {
  display: inline-block;
  background: #000;
  -webkit-transition: background 0.3s;
  -moz-transition: background 0.3s;
  -o-transition: background 0.3s;
  transition: background 0.3s; }

.ui-commandlink--social-media-dark path {
  -webkit-transition: fill 0.3s;
  -moz-transition: fill 0.3s;
  -o-transition: fill 0.3s;
  transition: fill 0.3s;
  fill: #fff; }

.ui-commandlink--social-media-dark:hover {
  background: #777; }

.ui-commandlink--social-media-dark:hover path {
  fill: #000; }

.ui-commandlink--social-media-dark svg {
  height: 17px;
  margin-top: 8px; }

.ui-layout-west__footer a {
  text-decoration: none;
  color: #626262; }

.ui-dropdown__content {
  display: none; }

.ui-dropdown {
  color: #626262;
  position: relative;
  cursor: pointer; }

.ui-dropdown:hover .ui-dropdown__content {
  display: block; }

.ui-dropdown__content {
  position: absolute;
  left: 0;
  top: 22px;
  background: #f7f7f7;
  padding: 15px;
  text-align: left;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  width: 100%;
  min-width: auto;
  z-index: 1; }

.ui-dropdown__content a {
  display: block;
  line-height: 30px; }

.ui-dropdown > a {
  position: relative; }

.ui-dropdown > a:after {
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  right: -19px;
  top: 4px;
  background: url("../images/angle-down.svg") center no-repeat; }

.ui-text {
  display: inline-block; }

.ui-text--orange {
  color: #e08348; }

.ui-text--dark {
  color: #464646; }

.ui-panel__banner .ui-width--half {
  padding-top: 50px; }

.ui-testemonials-grid .ui-first {
  width: -moz-calc(100% - 130px);
  width: -webkit-calc(100% - 130px);
  width: calc(100% - 130px); }

.ui-testemonials-grid .ui-last {
  width: 90px;
  padding: 20px; }

.ui-testemonials-grid__subheader {
  font-family: "robotomedium";
  color: #1e1e1e;
  padding: 29px 13px 8px 23px;
  font-weight: bold; }

.ui-testemonials-grid__output {
  color: #545454; }

.ui-testemonials-grid__footer {
  padding: 14px 18px;
  background: #f7f7f7; }

span.ui-state--highlight {
  color: #de7f3d; }

.ui-clamp {
  width: 197px; }

.ui-clamp a {
  margin-top: 4px;
  display: block; }

.ui-clamp a:hover {
  text-decoration: underline; }

.ui-fixed .ui-layout-west {
  height: 197px; }

.ui-fixed .ui-layout-west__content {
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  z-index: 10;
  -webkit-box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.1); }

.ui-modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 999;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: #000;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */ }

.ui-sidebar__content {
  width: 300px;
  position: absolute;
  right: 0;
  top: 0;
  min-height: 100%;
  background: #fff; }

.ui-sidebar__content a {
  color: #626262;
  display: block;
  padding: 15px; }

.ui-sidebar__close {
  height: 24px;
  width: 24px;
  padding: 0; }

#backToTheTop {
  position: fixed;
  text-align: center;
  bottom: 80px;
  right: 30px;
  width: 30px;
  height: 30px;
  opacity: .5;
  background: black;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  display: none;
  z-index: 100; }

#backToTheTop:hover {
  cursor: pointer; }

.topIcon {
  display: inline-block;
  margin-top: 7px;
  width: 16px;
  height: 16px;
  background: url("../images/angle-up.svg") center no-repeat; }

label.error {
  color: red;
  font-size: 13px; }

.ui-inputfield--dialog.ui-margin-bottom--11 + label.error {
  margin-top: -9px;
  display: block;
  margin-bottom: 11px; }

.ui-item--promo .ui-state--highlight {
  background: #ff2552; }

.item .ui-state--highlight {
  padding: 0 2px;
  color: #fff; }

.ui-item--sale .ui-state--highlight {
  background: #0394ea; }

.ui-item--free .ui-state--highlight {
  background: #f39335; }

.ui-favorite svg {
  cursor: pointer;
  fill: #e0e0e0;
  -webkit-transition: 1s all ease;
  -moz-transition: 1s all ease;
  -o-transition: 1s all ease;
  transition: 1s all ease; }

.ui-favorite:hover svg {
  fill: #c72a27; }

.ui-read-more-link {
  position: absolute;
  bottom: 3px;
  color: #0394ea;
  right: 21px;
  z-index: 0; }

.ui-read-more-link:after {
  content: '';
  display: block;
  left: -60px;
  top: 0;
  width: 60px;
  height: 18px;
  position: absolute;
  background: url("../images/trans-white-grad-60x1.png") repeat-y 0 0; }

.ui-read-more {
  position: absolute;
  bottom: 3px;
  color: #0394ea;
  right: 21px;
  z-index: 0; }

.ui-read-more:after {
  content: '';
  display: block;
  left: -60px;
  top: 0;
  width: 60px;
  height: 18px;
  position: absolute;
  background: url("../images/trans-white-grad-60x1.png") repeat-y 0 0; }

.ui-read-less {
  color: #0394ea;
  display: block;
  z-index: 0; }

.wrapper {
  width: 680px;
  margin: 0 auto; }

.wrapper h1 {
  color: #555;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.15) 0px 0px 1px;
  letter-spacing: 2px; }

.js-shown {
  display: block; }

.js-shown-tr {
  display: table-row; }

.js-shown-su {
  display: table-row; }

.js-shown-ql {
  display: table-row; }

/**
 * #ICONS
 */
.ui-icon {
  width: 24px;
  height: 24px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -12px 0 0 -12px;
  background-repeat: no-repeat;
  background-position: center; }

.ui-icon--search {
  background-image: url("../images/icon-search.svg"); }

.ui-icon--bars {
  background-image: url("../images/bars.svg"); }

.ui-icon--close {
  background-image: url("../images/times.svg"); }

/**
 * #MEDIA
 */
@media screen and (max-width: 1200px) {
  .ui-panelgrid__background {
    width: auto; }
  .ui-search-column {
    padding: 0 10px;
    padding-left: 40px; }
  .ui-layout-west__content .ui-button {
    margin: 0; }
  .ui-layout-west__content .ui-button.ui-button--left {
    margin-right: 8px; }
  .ui-layout-west__content .ui-width--230 {
    width: 176px; } }

@media screen and (max-width: 1000px) {
  .ui-width--230 {
    width: 100% !important; }
  .ui-panel__banner {
    width: 100%;
    margin-left: -15px; }
  .ui-panel__banner .ui-width--half {
    width: 100%;
    min-height: 350px;
    background: rgba(255, 255, 255, 0.6); }
  .ui-panel__banner .ui-padding-left--30 {
    padding: 0; }
  .ui-layout-west__content {
    padding-top: 7px; }
  .ui-layout-west__content .ui-search-column {
    padding: 0; }
  .ui-layout-west__content td {
    display: block;
    margin-bottom: 10px;
    width: 100%;
    float: left; }
  .ui-layout-west__content td:last-child {
    margin: 0; }
  .ui-panelgrid--logos td {
    display: block;
    height: 67px;
    width: 25%;
    float: left;
    padding: 0;
    margin-bottom: 15px;
    background-size: 130px 100%; }
  .ui-testemonials-grid .ui-last {
    width: 55px;
    padding: 23px 15px; }
  .ui-testemonials-grid img {
    width: 55px; }
  .ui-testemonials-grid__output {
    padding-bottom: 10px; }
  .ui-testemonials-grid__subheader {
    padding: 9px 13px 8px 23px; }
  .ui-testemonials-grid .ui-first {
    width: -moz-calc(100% - 85px);
    width: -webkit-calc(100% - 85px);
    width: calc(100% - 85px); }
  .ui-panelgrid--col-4 td {
    display: block;
    width: 50%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left; }
  .ui-panelgrid--col-4 td:first-child {
    margin-bottom: 15px; }
  .ui-panelgrid--col-4 td:nth-child(2) {
    padding-right: 0;
    margin-bottom: 15px; }
  .ui-panelgrid--col-4 td:nth-child(3) {
    padding-left: 0; }
  .ui-panelgrid--sitemap {
    max-width: 570px;
    margin-left: auto;
    margin-right: auto; }
  .ui-footer__logo-wrapper {
    text-align: left;
    max-width: 570px;
    margin: 0 auto; }
  .ui-panelgrid__cell--content .ui-font-size--35 {
    font-size: 26px; }
  .ui-panelgrid__cell--content .ui-width--full-175 {
    width: 100%; }
  .ui-panelgrid__cell--content .ui-button--item {
    position: initial;
    float: right; } }

@media screen and (max-width: 930px) {
  .ui-testemonials-grid__footer > span {
    float: left;
    clear: both; } }

@media screen and (max-width: 767px) {
  .ui-panelgrid--col-3.ui-panelgrid--basic {
    max-width: 400px;
    margin: 0 auto; }
  .ui-panelgrid--col-3 td {
    display: block;
    width: 100%;
    padding: 0;
    padding-bottom: 15px;
    float: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .ui-layout-west__footer .ui-panelgrid {
    display: none; }
  .ui-button--navigation {
    display: block; }
  .ui-width--quad {
    width: 50%;
    margin: 10px 0; }
  .item > div {
    display: block !important; }
  .item .image {
    border-bottom: 1px solid #e0e0e0; } }

@media screen and (min-width: 768px) {
  .ui-sidebar--navigation {
    display: none; } }

@media screen and (max-width: 640px) {
  .ui-panelgrid--sitemap td:nth-child(4) {
    margin-top: 15px;
    width: 100%; }
  .ui-panelgrid--sitemap td:nth-child(4) td:last-child {
    width: 50%;
    width: -moz-calc(100% - 70px);
    width: -webkit-calc(100% - 70px);
    width: calc(100% - 70px); }
  .ui-clamp {
    width: 100%; }
  .ui-panelgrid--layout td {
    display: block; }
  .ui-panelgrid--layout .ui-panelgrid__cell--ad {
    width: 100%; } }

@media screen and (max-width: 600px) {
  .ui-panelgrid--logos td {
    width: 33%; } }

@media screen and (max-width: 500px) {
  .ui-panelgrid--col-4 td {
    width: 100%;
    padding: 0;
    margin-bottom: 15px; }
  .ui-panelgrid--col-4 td:last-child {
    margin-bottom: 0; }
  .ui-panelgrid--sitemap td {
    text-align: center; }
  .ui-panelgrid--sitemap td:last-child {
    text-align: left; }
  .ui-panelgrid--sitemap .ui-height--20 {
    height: 3px; }
  .ui-footer__logo-wrapper {
    text-align: center; } }

@media screen and (max-width: 465px) {
  .ui-panelgrid--logos td {
    width: 50%; }
  .ui-button--search {
    width: 55px; } }

/**
 * #SEMANTIC-UI
 */
.ui-dialog--register .dropdown {
  padding-top: 0.6em !important;
  padding-bottom: 0.6em !important; }

.ui-dialog--register .dropdown .search {
  padding-top: 0.55em !important;
  padding-bottom: 0.6em !important; }

.ui-dialog--register .ui.selection.dropdown {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  font-size: 14px;
  border: 1px solid #919191;
  min-height: 32px;
  height: 32px;
  width: 100%; }

.ui-dialog--register .ui.selection.dropdown .menu {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.ui-dialog--register .ui.selection.dropdown .item {
  font-size: 14px; }

.ui-panelgrid__cell--content .ui.items.divided .item {
  display: table;
  table-layout: fixed;
  background: #f7f7f7;
  padding: 0;
  border: 1px solid #e0e0e0 !important;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  margin-bottom: 10px; }

.ui-panelgrid__cell--content .ui.items.divided .item .image {
  border-right: 1px solid #e0e0e0;
  display: table-cell;
  vertical-align: middle;
  background: url("../images/tag.svg") center no-repeat;
  -webkit-background-size: 50%;
  background-size: 50%; }

.ui-panelgrid__cell--content .ui.items.divided .item .image table {
  background: #f7f7f7; }

.ui-panelgrid__cell--content .ui.items.divided .item .content {
  padding: 10px; }

.ui.items {
  margin: 0.8em 0; }

.ui.items > .item .meta {
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 13px; }

.ui.items > .item > .content p {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4); }

.ui.items > .item .extra {
  font-size: 13px; }

.ui.items > .item > .image:not(.ui) {
  width: 115px; }

.ui.items > .item .extra > * {
  margin: 0; }

.description {
  margin-top: 5px;
  margin-bottom: 15px; }

.ui-inputfield--search {
  position: relative;
  background: #f7f7f7;
  display: block;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  padding: 8px 8px;
  line-height: 22px; }

.ui-inputfield--search * {
  font-family: "robotoregular", "robotolight", "robotomedium" !important;
  font-weight: normal !important;
  font-size: 14px !important; }

.ui-inputfield--search.ui.search .results .result .title {
  float: left; }

.ui-inputfield--search.ui.search .results .result .description {
  color: #9f3a38;
  float: right; }

.ui-inputfield--search.ui.search input {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: 0;
  background: none;
  padding: 0; }

.ui-inputfield--search.ui.search input:focus {
  background: none; }

.ui-inputfield--search ::-webkit-input-placeholder {
  font-size: 16px;
  color: #bac0bc; }

.ui-inputfield--search :-moz-placeholder {
  font-size: 16px;
  color: #bac0bc; }

.ui-inputfield--search ::-moz-placeholder {
  font-size: 16px;
  color: #bac0bc; }

.ui-inputfield--search :-ms-input-placeholder {
  font-size: 16px;
  color: #bac0bc; }

.ui-search-column .ui.search .results {
  width: 100%;
  width: -moz-calc(100% - 80px);
  width: -webkit-calc(100% - 80px);
  width: calc(100% - 80px); }
