



.ui-panelgrid__cell--content .ui.items.divided  {
  .item {
    display: table;
    table-layout: fixed;
    background: $search_bg;
    padding: 0;
    border: 1px solid $searchbar_border_c !important;
    @include vendor(box-shadow,0 1px 1px 0 $transparent_black_8);
    margin-bottom: 10px;
    .image {
      border-right: 1px solid $searchbar_border_c;
      display: table-cell;
      vertical-align: middle;
      background: $url_icon_tag center no-repeat;
      -webkit-background-size: 50%;
      background-size: 50%;
      table {
        background: $search_bg;
      }
    }
    .content {
      padding: 10px;
    }
  }
}

.ui.items {
  margin: 0.8em 0;
}

.ui.items>.item .meta {
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 13px;
}

.ui.items>.item>.content p {
  font-size: 14px;
  color: $transparent_black_40;
}

.ui.items>.item .extra {
  font-size: 13px;
}

.ui.items>.item>.image:not(.ui) {
  width: 115px;
}

.ui.items>.item .extra>* {
  margin: 0;
}
.description {
  margin-top: 5px;
  margin-bottom: 15px;
}
