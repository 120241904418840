.coupon {
    @include clearfix;
    
    padding: 20px 30px;
    border-bottom: 1px solid $block_border_color;
    // position: relative;

    &--stores {
        border-left: 1px solid $block_border_color;
        border-right: 1px solid $block_border_color;

        &:first-child {
            border-top: 1px solid $block_border_color;
        }
    }

    &__type {
        text-transform: uppercase;
        color: white;
        padding: 0 2px;
        font-size: 13px;

        &--promocode {
            background: $highlight_pink_bg;
        }

        &--sale {
            background: $highlight_blue_bg;
        }

        &--printable {
            background: $highlight_purple_bg;
        }
    }

    &__times-used {
        font-size: 13px;
        color: $color_dark_gray;
    }

    &__logo-wrapper {
        float: left;
        width: 20%;
        position: relative;
        top: 30px;
    }

    &__content {
        float: left;
        width: 60%;
    }

    &__description {
        position: relative;
    }

    &__c-read-more {
        position: relative;
        right: -80%;
        font-weight: bold;
        background: url(../images/trans-white-grad-60x1.png) repeat-y 0 0;
    }

    &__shop-btn {
        padding: 5px 10px;
        color: white;
        position: relative;
        top: 50px;
        left: 20px;
    }

    &__code-expires {
        position: relative;
    }


    &--paginated {
        display: none;
    }

    //Only some design for modal
    &__modal {
        // width: 100vw;
        // height: 640px;
        background: rgba(black, 0.3);
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 0;
        
    }

    &__modal-window {
        width: 640px;
        background: white;
        position: absolute;
        z-index: 12;
        top: 140px;
        left: 50%;
        transform: translateX(50%);


        &--stores {
            transform: translateX(-15%);
        }
    }

    &__modal-header {
        padding: 10px 0;
    }

    &__modal-logo {
        width: 100px;
        position: relative;
        top: 5px;
    }

    &__modal-body {

    }

    &__modal-code {
        text-align: center;
        margin: 0 120px;
        margin-top: 40px;
        padding: 15px 10px;
        border: 2px dotted orangered;
        background: rgba(orangered, 0.1);
        position: relative;
        margin-bottom: 15px;

        &--qr {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &__modal-code-text {
        word-wrap: break-word;
        font-size: 22px;
    }

    &__modal-copy-btn {
        display: inline-block;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        background: $button_green_bg;
        border: none;
        border-radius: 4px;
        color: white;
        padding: 7px;
        font-weight: bold;
        cursor: pointer;
    }

    &__modal-footer {
        text-align: right;
        background: $gray_svg_bg;
        padding: 5px 20px;
        margin-top: 20px;
    }
}

@media (max-width: 1150px) {
    .coupon {

        &__logo-wrapper {
            width: 100%;
            text-align: center;
            top: 0;
        }

        &__content {
            width: 100%;
        }

        &__shop-btn {
            float: right;
            width: 100%;
            text-align: center;
            margin-top: 10px;
            top: 0;
            left: 0;
        }
    }
}

@media (max-width: 1000px) {
    .coupon {

        &__modal {
            width: 100%;
        }

        &__modal-window {
            left: 0;
            transform: none;
            width: 100%;
            padding-right: 0;
        }

        &__modal-code {
            margin: 20px 10px;
        }

    }
}

