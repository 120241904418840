.js-shown {
    display: block;
}

.js-shown-tr {
    display: table-row;
}

.js-shown-su {
    display: table-row;
}

.js-shown-ql {
    display: table-row;
}