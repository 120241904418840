// 6 column grid for owl carousel so not be surprised for width value :)
.grid-6-col {
  @include clearfix;

  &__1-of-6 {
    display: block;
    float: left;
    width: 100%;

    &:not(:last-child){
      margin-right: 1.02%;
    }
  }
}


//Grid with the sidebar

.grid-3-5-col {
  @include clearfix;
  
  &__1-of-3-5 {
    display: block;
    width: calc(100% / 3.5);
    float: left;
  }
  
  &__2x1-of-3-5 {
    display: block;
    float: left;
    width: calc((100% - (100% / 3.5)) - 10px);
    margin-left: 10px;
  }
}

//Simple 3 column grid

.grid-3-col {
  @include clearfix;
  
  &__1-of-3 {
    display: block;
    float: left;
    width: calc(99.9% / 3);

    &--inv-socials {
      width: calc((99.9% / 3) - 10px);

      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }

  &__2-of-3 {
    display: block;
    float: left;
    width: calc(99% - (99.9% / 3));
  }

  &__3-of-3 {
    display: block;
    float: left;
    width: 100%;

    &--inv-socials {
      width: calc(100% - 10px);

      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}

//Simple 4 column grid

.grid-4-col {
  @include clearfix;

  &__1-of-4 {
    display: block;
    float: left;
    width: calc(100% / 4);
  }

  &__3-of-4 {
    display:block;
    float: left;
    width: calc(100% - (100% / 4));
  }
}

//Seimple 2 column grid

.grid-2-col {
  @include clearfix;

  &__1-of-2 {
      display: block;
      float: left;
      width: 50%;
  }

  &__2-of-2 {
    display: block;
    float: left;
    width: 100%;
}
}

.clr {
  @include clearfix;
}

@media (max-width: 800px) {
  .grid-3-col {

    &__1-of-3 {

      &--inv-socials {
        width: 100%;
        margin: 0;

        &:not(:first-child) {
          margin-left: 0;
          margin-top: 10px;
        }
      }
    }
  }
}