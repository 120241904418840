.stores {
	
		
	&__fav-but {
		cursor: pointer;
		position: absolute;
		left: 6px;
		top: 50%;
		transform: translateY(-50%);
		font-size: 20px;
		color: #ccc;

		&--checked {
			color: red;
		}
	}

	&__items {
		list-style: none;
		padding: 0;
		border-top: 1px solid $block_border_color;
		margin: 0;
	}

	&__item {
		border-bottom: 1px solid $block_border_color;
		padding: 30px 30px;
		padding-left: 38px;
		position: relative;
		z-index: 0;

		&--paginated {
			display: none;
		}
	}
		
	&__item-name {
		color: $green_link_color;
	}

	&__coupons {
		color: $commandLink_gray;
		font-size: 14px;
	}

	&__cashback {
		color: $color_red;
		font-weight: bold;
	}

	&__shop-btn {
		width: auto;
		text-align: center;
		padding: 5px 15px;
		background: $color_red;
		border-radius: 5px;
		color: white;
		position: absolute;
		top: 50%;
		right: 25px;
		transform: translateY(-50%);
		transition: background-color .5s;

		&:hover {
			background: #f54;
		}
	}
}

@media (max-width: 800px) {
	.stores {

		&__item-name {
			width: 50%;
		}

		&__coupons {
			display: none;
		}

		&__cashback {
			display: none;
		}
	}
}

