.helpLink {
    border-radius: 4px;
    color: white;
    position: fixed;
    bottom: 30px;
    right: 30px;
    display: block;
    padding: 6px 20px;
    font-size: 18px;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,.2);
}