
///*------------------------------------*\
//    #PANEL basic div component with ability to contain footer, header, and content
//\*------------------------------------*/

.ui-panel__banner {
  background: $url_banner_img no-repeat;
  min-height: 400px;
  margin-top: -2px;
}

.ui-panel--all-stores {
  border: 1px solid $searchbar_border_c;
  .ui-panel__header {
    background: $url_all_stores_img 0 100% no-repeat;
    height: 150px;
    background-size: 100% auto;
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0)), color-stop(0%, rgba(0,0,0,0)), color-stop(100%, rgba(0,0,0,0.2)));
      background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%);
      background: -o-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%);
      background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%);
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
    }
  }
  .ui-panel__content {
    padding: 1em 1em 0 1em;
  }

}

.ui-panel {
  &--favorite {
    border: 1px solid $searchbar_border_c;
    margin-right: 1em;
    margin-top: 1em;
    @include vendor(box-shadow, 0 1px 1px 0 $transparent_black_8);
    .ui-panel__header {
      padding: 1em;
    }
    .ui-panel__content {
      padding: 1em;
      padding-top: 0;
    }

  }
  &__content.ui-overflow--hidden {
    margin-bottom: 1em;
  }
}
