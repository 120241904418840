
///*------------------------------------*\
//    #INPUTFIELD this includes all input elements, <input><textarea> etc.
//\*------------------------------------*/


.ui-inputfield--dialog {
  @include vendor-o(transition,border-color .3s ease,box-shadow .3s ease);
  border: 1px solid $dark_input_border;
  line-height: 22px;
  font-size: 14px;
  padding: 4px;
  padding-left: 10px;
  outline: medium none;
  height: 22px;
  &:focus {
    border: 1px solid #6CA5D3;
    @include vendor(box-shadow,0 1px 1px 0 $transparent_black_18);
  }
}

.input-text {
  border: 1px solid $block_border_color;
  padding: 5px;
  padding-right: 30px;
  background: $search_bg;
}
