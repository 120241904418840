
///*------------------------------------*\
//    #INPUTFIELD this includes all input elements, <input><textarea> etc.
//\*------------------------------------*/

//wrapper around input element
.ui-inputfield {

  //removing some browser predefined properties for input element
  input {
    border: 0;
    width: 100%;
    padding: 0;
    font-size: 15px;
    background: none;
    color: $search_color;
    //for removing outline when input is focused
    &:focus {
      outline: 0;
    }
  }
}
