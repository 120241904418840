//Here is styles for text with small trangle at the end 

.traftext {
    position: relative;

    &::after {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left-width: 4px;
        border-left-style: solid;
        border-left-color: #f30;
        position: relative;
        right: -3px;
    }

    &--green {

        &::after{
            border-left-color: $color_green;
        }
    }

    &--white {
        &::after {
            border-left-color: $white;
        }
    }

    &--px6 {
        &::after {
            border-left-width: 6px;
        }
    }
}