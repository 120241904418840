.modal-coupon-login {
    display: none;
    position: absolute;
    z-index: 9999;
    background: white;
    width: 760px;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    margin-top: 50px;
    padding: 35px;

    &__socials {
        float: right;
        margin-top: 30px;
    }

    &__social {
        display: inline-block;   
        color: white;
        padding: 8px 10px;
        min-width: 260px;
        border-radius: 4px;
        
        &:not(:last-child) {
            margin-bottom: 15px;
        }

        &--fb {
            background: $color_facebook_bg;
        }

        &--gp {
            background: $color_gp_bg;
        }
    }

    &__socicon {
        color: white;
        display: inline-block;
        width: 25px;
        font-size: 18px;
    }

    &__or {
        display: none;
        border: 3px solid #d1cec7;
        padding: 10px 15px;
        border-radius: 50%;
        position: absolute;
        top: 56%;
        font-size: 18px;
    }

    &__without-cb {
        font-size: 22px;
        float: right;
    }
}

@media (max-width: 1000px) {
    .modal-coupon-login {
        width: 100%;
        position: absolute;

        &__or {
            display: none;
        }
    }
}


@media (max-width: 650px) {
    .modal {

        &__login-form {
            width: 100%;
            text-align: center;
        }
    }

    .modal-coupon-login__socials {
        width: 100%;
        // float: none;
        text-align: center;
    }
}