
///*------------------------------------*\
//    #PANELGRID is a low data table.
//\*------------------------------------*/

//subheader in panelgrid (larger darker text)
.ui-panelgrid {
  border-spacing: 0;
  &__subheader {
    font-family: $font_3;
    color: $separator_color;
    padding: 15px 13px 8px 13px;
    font-weight: bold;
  }
  &__output {
    //font-size: 16px;
    color: $panelgrid_output_color;
  }
  &__image {
    height: 100px;
    background: center center / auto no-repeat;
  }
  &__footer {
    padding: 23px 0 13px 13px;
  }
}
