.modal {
    
    display: none;


    &--active {
        display: block;
    }

    &__caption {
        background: $color_popup_caption_bg;
        border-radius: 2px;
        padding: 20px 30px;
        padding-left: 50px;
        margin: 25px 0;
    }

    &__cap-error {
        font-weight: bold;
        position: relative;
    }

    &__capicon {
        position: absolute;
        font-size: 33px;
        left: -40px;
    }

    &__close-btn {
        position: absolute;
        top: -30px;
        right: 0px;
        font-size: 22px;
        background: none;
        border: none;
        cursor: pointer;
        color: white;
    }

    &__label {
        font-weight: bold;
    }

    //Modal windows inputs

    &__input-t {
        padding: 8px 10px;
        border-radius: 4px;
        width: 90%;
        border: 1px solid $block_border_color;
        margin-bottom: 15px;
        margin-top: 10px;

        &--help {
            border-bottom-width: 2px;
            width: 300px;
        }
    }

    &__input-select {
        padding: 8px 10px;
        width: 300px;
        border: 1px solid $block_border_color;
        margin-bottom: 15px;
        margin-top: 10px;
    }

    &__textarea {
        border: 1px solid $block_border_color;
        resize: none;
        padding: 10px;
        margin-bottom: 15px;
        margin-top: 10px;
    }

    &__link {
        color: $color_green;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    &__btn {
        display: inline-block;
        background: none;
        border: none;
        border-radius: 4px;
        color: white;
        font-weight: bold;
        margin: 15px 0;
        font-size: 18px;
        padding: 0 35px;
    }

    &__overlay {
        // display: none;
        position: fixed;
        top: 0;
        width: 100vw;
        left: 0;
        height: 100vh;
        background: rgba(black, 0.3);
        z-index: 11;

        &--shown {
            display: block;
        }
    }

}


.overlay {
    display: none;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(black, 0.3);
    z-index: 9998;

    &--shown {
        display: block;
    }
}

.modal-email-prev {
    position: fixed;
    display: none;
    z-index: 10000;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    width: 640px;
    height: 450px;
    background: white url(../images/email-preview.1.0.0.jpg) no-repeat;
    background-position-y: bottom;

    &--shown {
        display: block;
    } 
}


.modal-shown {
    display: block;
}

@media (max-width: 480px) {
    .modal {

        &__input-t {
            width: 90%;
        }

        &__input-select {
            width: 90%;
        }

        &__textarea {
            width: 90%;
        }

        &__close-btn {
            right: 20px;
        }
    }
}


//Modal log-in please pop-up
@import 'modals/modal-coupon-login';

//Modal pop-up with contact form (help.html)
@import 'modals/modal-help-email';