.breadcrumbs {
    // margin: 15px 0;
    padding: 5px 0px;

    &__crumbs {
        display: block;
    }

    &__crumb {
        display: inline-block;
        padding-right: 15px;

        &:not(:last-child)::after {
            content: "›";
            position: relative;
            right: -10px;
            font-size: 18px;
            color: $small_grey;
        }
    }

    &__crumb-link {
        font-size: 12px;
        color: $small_grey;

        &:not(&--current) {
            

            &:hover {
                color: $green_link_color;
            }
        }
    }
}