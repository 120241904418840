
///*------------------------------------*\
//    #DIALOG
//\*------------------------------------*/

.ui-dialog {
  &__content {
    background: $white;
    width: 100%;
    max-width: 370px;
    margin: 0 auto;
  }
  &__header {
    background: $search_bg;
    padding: 6px 0;
  }
}
