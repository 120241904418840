
///*------------------------------------*\
//    #BODY all <body> tag styles
//\*------------------------------------*/
@font-face {
	font-family: $font_1;
	src: $url_49;
	src: $url_50 format('embedded-opentype'), $url_51 format('woff2'), $url_52 format('woff'), $url_53 format('truetype'), $url_54 format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: $font_2;
	src: $url_43;
	src: $url_44 format('embedded-opentype'), $url_45 format('woff2'), $url_46 format('woff'), $url_47 format('truetype'), $url_48 format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: $font_3;
	src: $url_55;
	src: $url_56 format('embedded-opentype'), $url_57 format('woff2'), $url_58 format('woff'), $url_59 format('truetype'), $url_60 format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: $font_1;
	src: $url_63;
	src: $url_64 format('embedded-opentype'), $url_65 format('woff2'), $url_66 format('woff'), $url_67 format('truetype'), $url_68 format('svg');
	font-weight: bold;
	font-style: normal;
}
@font-face {
	font-family: $font_2;
	src: $url_63;
	src: $url_64 format('embedded-opentype'), $url_65 format('woff2'), $url_66 format('woff'), $url_67 format('truetype'), $url_68 format('svg');
	font-weight: bold;
	font-style: normal;
}
@font-face {
	font-family: $font_3;
	src: $url_63;
	src: $url_64 format('embedded-opentype'), $url_65 format('woff2'), $url_66 format('woff'), $url_67 format('truetype'), $url_68 format('svg');
	font-weight: bold;
	font-style: normal;
}

//Iconic font from fontawesome

@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"); 

body {
  margin: 0;
  font-family: $font_1, $font_2, $font_3;
}

*, *::before, *::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

img {
	border: none;
}

h1 {
	font-weight: 400;
}

h2 {
	font-weight: 400;
}

h3 {
	font-size: 20px;
	font-weight: 400;
}

h4 {
	font-size: 14px;
	font-weight: 400;
}