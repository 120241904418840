.stores-recommended {

	display: block;
	margin-bottom: 20px;
	&__rec {
		display: block;
		box-sizing: border-box;
		text-align: center;
		border: 1px solid $block_border_color;
		padding: 35px 5px;


		&:hover {
			box-shadow:0 8px 14px -6px rgba(153,153,153,.65);
		}
	}

	&__old-cashback {
		font-size: 12px;
		color: $small_grey;
	}

	&__cashback {
		font-weight: bold;
		color: $color_red;
		font-size: 16px;
	}

	&__see-all {
		font-size: 12px;
		color: $small_grey;
	}

	&__logo {
		border: none;
		margin: 0 auto;
	}
}