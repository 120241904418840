.stores-sidebar {
	
	//Style for separated blocks in sidebar such as categories or promos
	&__promo {
			border: 1px solid $block_border_color;
			box-sizing: border-box;
			padding: 15px;
			margin-bottom: 10px;
		
		}
	
	&__categories {
			padding: 10px;
			box-sizing: border-box;
			margin-bottom: 10px;
			border: 1px solid $block_border_color;

		//categories-list 
		&-list {
			list-style: none;
			margin: 0;
			padding: 0 30px;
			padding-bottom: 30px;
		}
		

		//TODO: Replace this
		a {
			color: $cat_grey;

			&:hover {
				color: $cat_grey_hover;
			}
		}
	}

	&__subcategory {
		display: none;
		&--current {
			display: block;
		}

		&--expanded {
			display: block;
		}
	}

	&__cat-open {
		font-size: 21px;
		transition: all .5s;
		transform: rotate(0deg);

		&--checked {
			transform: rotate(90deg);
		}

		&:hover {
			color: black;
		}

	}
	
	
	//actually it is separator
	
	&__logo-wrapper {
		height: 1px;
		background: $gray_svg_bg;
		margin: 10px 0;
	}
	

	&__promo {
		
		&-banner {
			width: 100%;
		}
		
		&-logo {
			margin-top: 10px;
		}
		//TODO: Replace!
		p {
			margin: 0;
		}
		
		a {
			color: $color_red;
			font-size: 14px;
			
			&:hover {
				text-decoration: underline;
			}
			
			&::after {
				content: '';
				display: inline-block;
				width: 0;
				height: 0;
				border-top: 4px solid transparent;
				border-bottom: 4px solid transparent;
				border-left: 4px solid #f30;
				position: relative;
				right: -3px;
			}
		}
	}
	
}


@media (max-width: 800px) {
	.stores-sidebar {
		width: 100%;

		&__promo {
			display: none;
		}

		&__categories {
			text-align: center;
		}
	}

}