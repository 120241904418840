
///*------------------------------------*\
//    #BUTTON
//\*------------------------------------*/

//default button properties
.ui-button {
  @include vendor-o(transition,background-color .4s ease,box-shadow .4s ease);
  position: relative;
  padding: 0 18px;
  border: none;
  height: 40px;
  outline: 0;
  font-family: $font_1, $font_2, $font_3;
  cursor: pointer;
  &:focus {
		@include vendor(box-shadow,0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12) );
	}
}
