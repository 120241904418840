.modal-help-email {
    display: none;
    position: absolute;
    z-index: 9999;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    padding: 35px;
    width: 760px;
}

@media (max-width: 750px) {
    .modal-help-email {
        width: 100%;
    }
}