
///*------------------------------------*\
//    #ICONS all icon classes.
//\*------------------------------------*/

.ui-icon {
  width: 24px;
  height: 24px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -12px 0 0 -12px;
  background-repeat: no-repeat;
  background-position: center;
  &--search {
    background-image: $url_icon_search;
  }
  &--bars {
    background-image: $url_icon_bars;
  }
  &--close {
    background-image: $url_icon_close;
  }
}
