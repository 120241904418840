
///*------------------------------------*\
//    #LINKS all specific <a> tag classes
//\*------------------------------------*/

.ui-layout-west__footer {
  a {
    text-decoration: none;
    color: $w_f_link_color;
  }
}
.ui-dropdown {
  &__content {
    display: none;
  }
}
//if it has dropdown it has to be
.ui-dropdown {
  color: $w_f_link_color;
  position: relative;
  cursor: pointer;
  &:hover {
    .ui-dropdown__content {
      display: block;
    }
  }
  &__content {
    position: absolute;
    left: 0;
    top: 22px;
    background: $search_bg;
    padding: 15px;
    text-align: left;
    @include vendor(box-shadow, 0 1px 1px 0 $transparent_black_8);
    width: 100%;
    min-width: auto;
    z-index: 1;
    a {
      display: block;
      line-height: 30px;
    }
  }
  >a {
    position: relative;
    &:after {
      content: '';
      display: block;
      width: 15px;
      height: 15px;
      position: absolute;
      right: -19px;
      top: 4px;
      background: $url_icon_angle_d center no-repeat;

    }
  }
}
