.promo-sticky {
	padding: 0;
	box-sizing: border-box;
	margin-bottom: 10px;  
	z-index: 0;
	
	&__heading {
		border: 1px solid $block_border_color;
		padding: 15px;
	}
	
	&__logo {
		max-width: 100%;
	}
	
	&__links {
		box-sizing: border-box;
		border: 1px solid $block_border_color;
		border-top: none;
		border-bottom: none;
	}
	
	&__link-wrapper {
		box-sizing: border-box;
		border-bottom: 1px solid $block_border_color;
		
		&:not(:nth-child(3n-1)) {
			border-right: 1px solid transparent;
			border-left: 1px solid transparent;
		}
		
		&:nth-child(3n-1) {
			border-right: 1px solid $block_border_color;
			border-left: 1px solid $block_border_color;
		}
	}
	
	&__link {
		display: block;
		box-sizing: border-box;
		padding: 25px 15px;
	}
}

@media (max-width: 800px) {
	.promo-sticky {
		display: none;
	}
}