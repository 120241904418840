
///*------------------------------------*\
//    #LAYOUT classes used to set page layout as needed.
//\*------------------------------------*/

/**
 * West layout styles (used as a web-page main header). ******************************************************************************************************************
 */
.ui-layout-west {
  &__content {
    max-width: 1170px;
    padding: 10px 15px;
    margin: 0 auto;
  }
}

.header__login {
  display: none;
}



/**
 * Center layout styles (used as a web-page main content). ******************************************************************************************************************
 */
.ui-layout-center {
  &__content {
    @extend %main-layout;
    // padding: 0 15px;
  }
}

/**
 * webpage footer ******************************************************************************************************************
 */
.ui-footer {
  margin-top: 50px;
  background: $footer_bg;
  &__content {
    padding-top: 30px;
    max-width: 1170px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}


@media (max-width: 1000px) {
  .header__login {
    display: inline;
  }

  .header__log-reg {
    display: none;
  }

  #loginBlock {
    display: none;
  }
}